import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FLOOR_AND_SPACES_STORE_NAME } from "../../constants";
import { IFloorAndSpacesState } from "./reducer";

const getFloorsAndSpacesState = createFeatureSelector<IFloorAndSpacesState>(
  FLOOR_AND_SPACES_STORE_NAME,
);

export const floorAndSpacesSelectors = {
  floors: createSelector(getFloorsAndSpacesState, (state) => state.floors),
  floorConfig: createSelector(
    getFloorsAndSpacesState,
    (state) => state.floorConfig,
  ),
  userGroups: createSelector(
    getFloorsAndSpacesState,
    (state) => state.userGroups,
  ),
  users: createSelector(getFloorsAndSpacesState, (state) => state.users),
  selectedFloorIndex: createSelector(
    getFloorsAndSpacesState,
    (state) => state.selectedFloorIndex,
  ),
  googleMeetingRooms: createSelector(
    getFloorsAndSpacesState,
    (state) => state.googleMeetingRooms,
  ),
  microsoftMeetingRooms: createSelector(
    getFloorsAndSpacesState,
    (state) => state.microsoftMeetingRooms,
  ),
  reSyncingMeetingRoomIds: createSelector(
    getFloorsAndSpacesState,
    (state) => state.reSyncingMeetingRoomIds,
  ),
};
