import { Injectable, inject } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import {
  AuthModel,
  FeatureAccessRoutingService,
  FeatureAccessService,
  FeatureFlagModel,
} from "auth-module";
import { DEFAULT_CLIENT_ROUTE, RouterModel } from "router-module";
import { combineLatest, filter, map, Observable, take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DefaultComponentActivate implements CanActivate {
  private routerModel = inject(RouterModel);
  private authModel = inject(AuthModel);
  private router = inject(Router);
  private featureAccessRoutingService = inject(FeatureAccessRoutingService);
  private featureAccessService = inject(FeatureAccessService);
  private featureFlagModel = inject(FeatureFlagModel);

  canActivate(): Observable<UrlTree> {
    return combineLatest([
      this.routerModel.isAdminAppEnv$,
      this.authModel.isRequiredToVisitSetupWizard$,
      this.authModel.isUserEmailVerified$,
      this.authModel.authenticatedUser$,
      this.authModel.selectors.isDeskbirdAdmin$,
      this.authModel.selectors.corporateInfo$.pipe(filter(Boolean)),
      this.featureFlagModel.featureEnableMPPS$,
      this.featureAccessService.featureAccess$,
      this.authModel.userRoleCompanyPageRestrictionsForAdmin$,
    ]).pipe(
      map(
        ([
          isAdminAppEnv,
          isRequiredToVisitSetupWizard,
          isUserEmailVerified,
          authUser,
          isDeskbirdAdmin,
          corporateInfo,
          featureEnableMpps,
          featureAccess,
          userRoleCompanyPageRestrictionsForAdmin,
        ]) => {
          if (!isUserEmailVerified) {
            return this.router.parseUrl("/login/verify-email");
          }
          if (isRequiredToVisitSetupWizard) {
            return this.router.parseUrl("/login/sign-up-wizard");
          }

          const initialUrl = isAdminAppEnv
            ? this.featureAccessRoutingService.getInitialRouteForAdministration(
                authUser,
                featureEnableMpps,
                isDeskbirdAdmin,
                featureAccess,
                null,
                userRoleCompanyPageRestrictionsForAdmin,
              )
            : this.featureAccessRoutingService.getInitialRouteForClient(
                featureEnableMpps,
                corporateInfo,
                featureAccess,
              );

          return initialUrl
            ? this.router.parseUrl(initialUrl)
            : this.router.parseUrl(DEFAULT_CLIENT_ROUTE);
        },
      ),
      take(1),
    );
  }
}
