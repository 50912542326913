@if (
  hasAdminNavigationAccess &&
  ([adminPartPrefix, "default"] | exec: hasAccessToPage)
) {
  <db-menu-item
    [link]="[adminPartPrefix, 'default']"
    [style]="'nav-with-border'"
    [hideIcons]="hideIcons"
    imgSrc="/assets/icons/admin.svg"
    title="Admin"
    i18n-title="@@navigation-module|client-navigation|admin"
  >
  </db-menu-item>

  <hr />
}

@if (
  hasPlanningAccess && ([clientPartPrefix, "planning"] | exec: hasAccessToPage)
) {
  <db-menu-item
    *dbFeatureAccess="[FeatureAccessType.SCHEDULING]"
    [style]="'webapp'"
    imgSrc="/assets/icons/scheduling_new.svg"
    title="Schedule"
    [link]="[clientPartPrefix, 'planning']"
    [hideIcons]="hideIcons"
    i18n-title="@@navigation-module|client-navigation|plan"
  >
  </db-menu-item>
}
@if (
  hasBookingAccess &&
  ([
    clientPartPrefix,
    officeId ? "office" : "office-redirect",
    officeId ? officeId : "default",
    "bookings",
    "dashboard",
  ] | exec: hasAccessToPage)
) {
  <db-menu-item
    *dbFeatureAccess="[
      FeatureAccessType.RESOURCE_BOOKING,
      FeatureAccessType.ROOMS,
    ]"
    [link]="[
      clientPartPrefix,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'bookings',
      'dashboard',
    ]"
    [queryParams]="{ allBookings: $any(undefined) }"
    [dataTestId]="'navigation--book--item'"
    [style]="'webapp'"
    [imgSrc]="
      (showMeetingRoomsIcon$ | async)
        ? '/assets/icons/meeting-room-area-type.svg'
        : '/assets/icons/book.svg'
    "
    title="Book"
    [queryParamsHandling]="'merge'"
    [hideIcons]="hideIcons"
    i18n-title="@@navigation-module|client-navigation|book"
  />
}
@if (
  hasBookingAccess &&
  ([clientPartPrefix, "office", ALL_OFFICES, "bookings"]
    | exec: hasAccessToPage)
) {
  <db-menu-item
    *dbFeatureAccess="[
      FeatureAccessType.RESOURCE_BOOKING,
      FeatureAccessType.ROOMS,
    ]"
    [link]="[clientPartPrefix, 'office', ALL_OFFICES, 'bookings']"
    [style]="'webapp'"
    [queryParams]="{ isFullDay: $any(undefined) }"
    imgSrc="/assets/icons/bookings_new.svg"
    title="Bookings"
    [queryParamsHandling]="'merge'"
    [dataTestId]="'navigation--bookings--item'"
    [hideIcons]="hideIcons"
    i18n-title="@@navigation-module|client-navigation|bookings"
  >
  </db-menu-item>
}
@if (featureEnableVisitorsManagement$ | async) {
  @if (
    [
      clientPartPrefix,
      officeId ? "office" : "office-redirect",
      officeId ? officeId : "default",
      "visits",
    ] | exec: hasAccessToPage
  ) {
    <db-menu-item
      [link]="[
        clientPartPrefix,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'visits',
      ]"
      [style]="'webapp'"
      imgSrc="/assets/icons/visitor-badge.svg"
      title="Visits"
      [queryParamsHandling]="'merge'"
      [hideIcons]="hideIcons"
      i18n-title="@@navigation-module|client-navigation|visits"
    />
  }
}

<div class="footer">
  <div class="support-info">
    <a
      class="cta-help-center small-text"
      href="https://deskbird.zendesk.com/hc/en-us"
      target="_blank"
    >
      <img
        src="assets/icons/help-center-purple.svg"
        alt="help"
        class="help-icon"
      />
      <div class="text" i18n="@@navigation-module|help-center">Help center</div>
      <img src="assets/icons/link-purple.svg" alt="link" class="link-icon" />
    </a>
  </div>
  <hr />

  <div class="menu-trigger" (click)="menu.toggle($event)">
    <db-user-info
      [onlyImage]="true"
      [userInfo]="userInfo"
      [avatarSize]="'standard'"
      data-testid="navigation--profile--menu-trigger"
    ></db-user-info>
  </div>
  <db-menu
    #menu
    [items]="(userMenuItems$ | async) ?? []"
    [popup]="true"
  ></db-menu>
</div>
