<div class="field">
  @if (!!label) {
    <label [for]="inputId" class="block small-text-bold">
      {{ label }}
      @if (showAsterisk) {
        *
      }
      @if (optional) {
        <i i18n="@@shared|optional-label">(optional)</i>
      }
    </label>
  }
  @if (type === "text") {
    <input
      [id]="inputId"
      pInputText
      dbTrim
      #input="ngModel"
      #inputElement
      [pattern]="pattern"
      [placeholder]="placeholder"
      [required]="required === undefined ? false : true"
      [disabled]="disabled"
      [ngModel]="value"
      [name]="name"
      [attr.data-testId]="dataTestId"
      (ngModelChange)="valueChangedHandler($event)"
      (focus)="focusHandler()"
      [dbGtmEvent]="gtmEvent"
      [trackFocus]="trackFocus"
      [autocomplete]="disableAutocomplete ? 'off' : 'on'"
      [ngClass]="addClasses"
      [pKeyFilter]="keyFilter"
    />
    @for (error of errorDictionaryList; track error) {
      @if (
        !hideInternalError &&
        (input.dirty || input.touched || !!control?.touched) &&
        (input.errors?.[error.name] || control?.getError(error.name))
      ) {
        <small class="db-input-error block">
          {{
            error.getLabel(
              input.errors?.[error.name] || control?.getError(error.name)
            )
          }}
        </small>
      }
    }
  }
  @if (type === "number") {
    <div class="p-fluid">
      <p-inputNumber
        [id]="inputId"
        #input="ngModel"
        [pattern]="pattern"
        [placeholder]="placeholder"
        [required]="required === undefined ? false : true"
        [disabled]="disabled"
        [ngModel]="value"
        [name]="name"
        [min]="min"
        [max]="max"
        [suffix]="suffix"
        [attr.data-testId]="dataTestId"
        (ngModelChange)="valueChangedHandler($event)"
        [dbGtmEvent]="gtmEvent"
        [trackFocus]="trackFocus"
        [autocomplete]="disableAutocomplete ? 'off' : 'on'"
        [useGrouping]="useNumberGrouping"
      ></p-inputNumber>
      @for (error of errorDictionaryList; track error) {
        @if (
          !hideInternalError &&
          (input.dirty || input.touched || !!control?.touched) &&
          (input.errors?.[error.name] || control?.getError(error.name))
        ) {
          <small class="db-input-error block">
            {{
              error.getLabel(
                input.errors?.[error.name] || control?.getError(error.name)
              )
            }}
          </small>
        }
      }
    </div>
  }
  @if (type === "textarea") {
    <textarea
      #input="ngModel"
      [placeholder]="placeholder"
      [required]="required === undefined ? false : true"
      [ngModel]="value"
      [name]="name"
      [id]="inputId"
      [disabled]="disabled"
      [attr.data-testId]="dataTestId"
      dbTrim
      pInputTextarea
      (ngModelChange)="valueChangedHandler($event)"
      [dbGtmEvent]="gtmEvent"
      [trackFocus]="trackFocus"
      [autocomplete]="disableAutocomplete ? 'off' : 'on'"
    >
    </textarea>
    @for (error of errorDictionaryList; track error) {
      @if (
        !hideInternalError &&
        (input.dirty || input.touched || !!control?.touched) &&
        (input.errors?.[error.name] || control?.getError(error.name))
      ) {
        <small class="db-input-error block">
          {{
            error.getLabel(
              input.errors?.[error.name] || control?.getError(error.name)
            )
          }}
        </small>
      }
    }
  }
</div>
