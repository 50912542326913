<div
  class="accordion-header flex items-end"
  [style.justifyContent]="
    styleArrowInHeader === 'right-aligned' ? 'space-between' : 'flex-start'
  "
>
  <ng-content select="[dbAccordionItemHeader]"></ng-content>
  @if (!viewOnly && !isOpened) {
    <db-button-icon
      icon="pi pi-chevron-down"
      (click)="toggleOpenedHandler(true)"
    ></db-button-icon>
  }
  @if (!viewOnly && isOpened) {
    <db-button-icon
      icon="pi pi-chevron-up"
      (click)="toggleOpenedHandler(false)"
    ></db-button-icon>
  }
</div>

@if (isOpened) {
  <div class="accordion-content">
    <ng-content select="[dbAccordionItemContent]"></ng-content>
  </div>
}
