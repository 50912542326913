import { Params } from "@angular/router";
import {
  MeetingRoomCalendarData,
  MeetingRoomCalendarEventData,
  MeetingRoomWithFloor,
} from "types/interfaces";
import { getEventsForMeetingRoom } from "./get-events-for-meeting-room";
import moment from "moment";
import { TIME_FORMAT_H24 } from "shared-constants";

export const generateTimeBlockersForDay = (
  timezone: string,
  meetingRooms: MeetingRoomWithFloor[],
  meetingRoomCalendarData: MeetingRoomCalendarData[] | null,
  routeParams: Params,
) => {
  const { meetingRoomId, seriesId, virtualId, bookingId } = routeParams;

  const meetingRoomData = (meetingRooms || []).find(
    (mr) => mr.id === meetingRoomId,
  );
  const meetingRoomEvents = getEventsForMeetingRoom(
    meetingRoomData!,
    meetingRoomCalendarData!,
  );
  if (!meetingRoomData) {
    return [];
  }

  const filterEventBeingEdited = (
    event: MeetingRoomCalendarEventData,
  ): boolean => {
    const isEventBeingEdited =
      (!!seriesId &&
        !!virtualId &&
        event.seriesId === seriesId &&
        event.virtualId === virtualId) ||
      (!!bookingId && event.id === bookingId);
    return !isEventBeingEdited;
  };

  return meetingRoomEvents.filter(filterEventBeingEdited).map((event) => {
    const startTimeWithBuffer = moment(event.startTime)
      .tz(timezone)
      .subtract(event.bufferBeforeMinutes || 0, "minutes");
    const endTimeWithBuffer = moment(event.endTime)
      .tz(timezone)
      .add(event.bufferAfterMinutes || 0, "minutes");

    if (
      startTimeWithBuffer.isBefore(
        moment(event.startTime).tz(timezone).startOf("day"),
      )
    ) {
      startTimeWithBuffer.set({ hour: 0, minute: 0 });
    }

    if (
      endTimeWithBuffer.isAfter(moment(event.endTime).tz(timezone).endOf("day"))
    ) {
      endTimeWithBuffer.set({ hour: 23, minute: 59 });
    }

    return {
      startTime: startTimeWithBuffer.format(TIME_FORMAT_H24),
      endTime: endTimeWithBuffer.format(TIME_FORMAT_H24),
    };
  });
};
