import { Observable } from "rxjs";
import { PaginatedResult } from "types/interfaces/paginated-response";
import { ILoadUsersFilteredRequest_v3 } from "types/interfaces/user-filtered-request";
import { IUserInfo } from "types/interfaces/user-info";
import { IUserGroupSearchResult } from "types/interfaces/users/user-group-search-result";

export interface IUserFilterService {
  loadUsersForCompanyFiltered({
    companyId,
    searchQuery,
    primaryOfficeIds,
    roles,
    userGroups,
    onlyUsersWithNoGroups,
    officeRolesIds,
    followStatus,
    userIds,
    excludeUserIds,
    status,
    sortField,
    sortOrder,
    offset,
    limit,
    include,
  }: ILoadUsersFilteredRequest_v3): Observable<PaginatedResult<IUserInfo>>;

  loadGroupsForCompanyFiltered({
    companyId,
    searchQuery,
    groupIds,
    excludeGroupIds,
    include,
    offset,
    limit,
  }: {
    companyId: string;
    searchQuery?: string;
    groupIds?: string[];
    excludeGroupIds?: string[];
    include?: string[];
    offset: number;
    limit: number;
  }): Observable<PaginatedResult<IUserGroupSearchResult>>;
}
