export const FLOOR_AND_SPACES_STORE_NAME = "floorAndSpaces";
export const ZONE_ITEMS_STORE_NAME = "zoneItems";
export const uploadImageUrl = "/api/internalWorkspaces";
/** 6pm */
export const HOURS_IN_DAY = 24;
export const SECONDS_IN_HOUR = 3600;
export const RESTRICTED_TIME_HOUR = 18; // 6pm
export const restrictedTimeDefaultValue =
  RESTRICTED_TIME_HOUR * SECONDS_IN_HOUR;
export const ALREADY_RE_SYNCED_TODAY_STATUS = 403;
