@if (!isLoading && bookingProps) {
  <div class="info-col">
    @if (!fitDetailsOnOneLine) {
      <div class="details">
        <!-- GUEST NAME (IF SUCH)-->
        @if (bookingProps.guest) {
          <div class="small-text text-gray">
            {{ bookingProps.guest.firstName }} {{ bookingProps.guest.lastName }}
          </div>
        }
        <!-- RESOURCE NAME AND ICONS-->
        <div class="flex items-center">
          <!-- RESOURCE NAME -->
          <span class="small-text-bold text-gray truncate-text">{{
            resourceName
          }}</span>
          @if (
            bookingProps.zone &&
            bookingProps.zone.type !== deskAreaType.MeetingRoom &&
            bookingProps.zone.accessRules
          ) {
            <db-area-restriction-icon
              [accessRules]="bookingProps.zone.accessRules"
            >
            </db-area-restriction-icon>
          }
          <!-- DEDICATED ICON -->
          @if (isDedicatedResource) {
            <img
              src="/assets/icons/dedicated-resource.svg"
              alt="dedicated resource"
              height="14"
            />
          }
          <!-- FAVORITE FEATURE -->
          @if (
            bookingProps.zone &&
            bookingProps.zoneItem &&
            bookingProps.zone.type !== deskAreaType.MeetingRoom &&
            isFavoriteIconPossible
          ) {
            <db-favorite-space-action-icon
              [resourceItem]="bookingProps.zoneItem"
              [deskArea]="{ accessRules: bookingProps.zone.accessRules }"
              [gtmEntryPoint]="favoriteSpaceGtmEntryPoint"
              [ngClass]="{ hovered: isHovered }"
              [authUser]="{
                favoriteResources: authUserFavoriteResources,
                id: authUserId,
              }"
              [dataTestIdBase]="favoriteSpaceDataTestId || undefined"
            >
            </db-favorite-space-action-icon>
          }
        </div>
        <!-- AREA NAME -->
        @if (
          bookingProps &&
          bookingProps.zone &&
          bookingProps.zone.type !== deskAreaType.MeetingRoom &&
          bookingProps.zone.name
        ) {
          <div class="very-small-text">
            {{ bookingProps.zone.name }}
          </div>
        }
      </div>
    } @else {
      @if (bookingProps) {
        <div class="details one-line-details">
          <!-- RESOURCE NAME AND ICONS-->
          <div class="flex items-center">
            <!-- RESOURCE NAME -->
            <span class="small-text-bold text-gray truncate-text">{{
              resourceName
            }}</span>
            @if (
              bookingProps.zone &&
              bookingProps.zone.type !== deskAreaType.MeetingRoom &&
              bookingProps.zone.accessRules
            ) {
              <db-area-restriction-icon
                [accessRules]="bookingProps.zone.accessRules"
                [withRightMargin]="false"
              >
              </db-area-restriction-icon>
            }
            <!-- DEDICATED ICON -->
            @if (isDedicatedResource) {
              <img
                src="/assets/icons/dedicated-resource.svg"
                alt="dedicated resource"
                height="14"
              />
            }
            <!-- FAVORITE FEATURE -->
            @if (
              bookingProps.zone &&
              bookingProps.zoneItem &&
              bookingProps.zone.type !== deskAreaType.MeetingRoom &&
              isFavoriteIconPossible
            ) {
              <db-favorite-space-action-icon
                [resourceItem]="bookingProps.zoneItem"
                [deskArea]="{ accessRules: bookingProps.zone.accessRules }"
                [gtmEntryPoint]="favoriteSpaceGtmEntryPoint"
                [ngClass]="{ hovered: isHovered }"
                [authUser]="{
                  favoriteResources: authUserFavoriteResources,
                  id: authUserId,
                }"
                [midTextIcon]="true"
                [dataTestIdBase]="favoriteSpaceDataTestId || undefined"
              >
              </db-favorite-space-action-icon>
            }
          </div>
          <div class="vertical-dash"></div>
          <!-- AREA NAME -->
          @if (
            bookingProps &&
            bookingProps.zone &&
            bookingProps.zone.type !== deskAreaType.MeetingRoom &&
            bookingProps.zone.name
          ) {
            <div class="small-text">
              {{ bookingProps.zone.name }}
            </div>
          }
          @if (bookingProps.guest) {
            <div class="vertical-dash"></div>
            <div class="small-text-bold text-gray">
              {{ bookingProps.guest.firstName }}
              {{ bookingProps.guest.lastName }}
            </div>
            &nbsp;
            <div class="small-text" i18n="@@common|guest-with-brackets">
              (Guest)
            </div>
          }
        </div>
      }
    }
    <!-- BOOKING TIME RANGE -->
    <div class="very-small-text text-dark-gray">
      <db-booking-start-end-time
        [hourFormat]="authUserTimeFormat"
        [isFullDay]="bookingProps.isDayPass"
        [startTime]="bookingProps.bookingStartTime"
        [endTime]="bookingProps.bookingEndTime"
        [timeZone]="bookingProps.workspace.address?.timeZone"
      >
      </db-booking-start-end-time>
    </div>
  </div>
  <div class="status-col">
    <db-booking-status
      [booking]="bookingProps"
      [authUser]="{ hourFormat: authUserTimeFormat! }"
      [dataTestIdForCheckInCta]="
        bookingStatusDataTestIdForCheckInCta || undefined
      "
      (bookingCheckInConfirmation)="checkInHandler()"
    >
    </db-booking-status>
  </div>
}
@if (isLoading) {
  <div class="info-col">
    <db-skeleton-loader
      skeletonWidth="60px"
      skeletonHeight="15px"
      skeletonClass="mb-lg"
    ></db-skeleton-loader>
    <db-skeleton-loader
      skeletonWidth="90px"
      skeletonHeight="10px"
    ></db-skeleton-loader>
  </div>
  <div class="status-col">
    <db-skeleton-loader
      skeletonWidth="60px"
      skeletonHeight="20px"
    ></db-skeleton-loader>
  </div>
}
