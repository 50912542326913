import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DotLoaderComponent } from "../dot-loader";

export enum LoadingTenseState {
  Past,
  Present,
  Infinitive,
}

@Component({
  selector: "db-loading-tense",
  templateUrl: "./loading-tense.component.html",
  standalone: true,
  imports: [CommonModule, DotLoaderComponent],
})
export class LoadingTenseComponent {
  @Input() state!: LoadingTenseState;
  @Input() pastText!: string;
  @Input() presentText!: string;
  @Input() infinitiveText!: string;
  LoadingTenseState = LoadingTenseState;
}
