import { FeatureAccessType, FeatureQuotaType } from "types/enums";

export type FeatureQuota = {
  name: FeatureQuotaType;
  quantity: number;
  utilization: number;
  limit?: FeatureQuotaLimit;
};

export type FeatureAccess = {
  features: FeatureAccessType[];
  quotas: FeatureQuota[];
};

export enum FeatureQuotaLimit {
  SOFT = "SOFT",
  HARD = "HARD",
}
