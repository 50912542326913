<db-dialog-container
  (closeDialog)="handleSignOutConfirmationConfirmation(false)"
>
  <db-dialog-card>
    <ng-container uiCardHeader>
      <h3 i18n="@@confirmations|sign-out|title">
        Are you sure you want to sign out?
      </h3>
    </ng-container>
    <ng-container uiCardBody>
      <db-lottie-player
        width="250px"
        height="250px"
        margin="auto"
        [lottie]="signOutLottie"
        background="transparent"
      >
      </db-lottie-player>
    </ng-container>
    <ng-container uiCardFooter>
      <div class="actions flex w-full">
        <db-button
          class="ml-2xl flex-1"
          buttonType="outlined"
          width="full-width"
          (click)="handleSignOutConfirmationConfirmation(false)"
          i18n="@@confirmations|sign-out|button-no"
        >
          No, stay logged in
        </db-button>
        <db-button
          class="ml-2xl flex-1"
          width="full-width"
          [dataTestId]="'auth--sign-out-confirmation--button'"
          (click)="handleSignOutConfirmationConfirmation(true)"
          i18n="@@confirmations|sign-out|button-yes"
        >
          Yes, sign out
        </db-button>
      </div>
    </ng-container>
  </db-dialog-card>
</db-dialog-container>
