import { UserStatus } from "types/enums";

export interface IUserGroupSearchFilter {
  companyId: string;
  searchQuery?: string;
  groupIds?: string[];
  excludeGroupIds?: string[];
  include?: Array<"users" | "userCount">;
  offset: number;
  limit: number;
  /** The statuses to consider when including `users` or `userCount`. If not defined, BE has Active and Provisioned as default. */
  userStatuses?: UserStatus[];
}
