@if (!isResolving || userSpaces?.length) {
  <div
    class="user-spaces"
    data-testid="common--user-spaces-cards"
    [ngClass]="{ 'border-cards': showBorder }"
  >
    @for (
      userSpace of userSpaces;
      track trackByFn(i, userSpace);
      let i = $index
    ) {
      <div
        class="card"
        #hoverContainer="dbHoverContainer"
        dbHoverContainer
        data-testid="common--user-spaces-cards-card"
        [ngClass]="{
          occupied: userSpace.isOccupied,
          'with-border': showBorder,
          'full-width': takeFullWidth,
        }"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      >
        <div class="left">
          <div
            [ngClass]="
              fitDetailsOnOneLine ? 'flex items-center' : 'flex flex-col'
            "
          >
            <div class="resource-name small-text-bold">
              <span [ngClass]="{ 'full-width': takeFullWidth }">{{
                userSpace.zoneItemName
              }}</span>
              @if (userSpace.isDedicatedResource) {
                <i class="svg-dedicated-resource size-14"> </i>
              }
              @if (userSpace.isFavoriteResource) {
                <ng-container
                  *ngTemplateOutlet="
                    favoriteIconTemplate;
                    context: { userSpace, hoverContainer, authUser }
                  "
                >
                </ng-container>
              }
            </div>
            @if (fitDetailsOnOneLine) {
              <div class="vertical-dash ml-0"></div>
            }
            <div class="area-name small-text">
              {{ userSpace.zoneName }}
            </div>
          </div>
          @if ((userSpace.bookings || [])[0]; as booking) {
            <div class="very-small-text text-light-gray">
              <db-booking-start-end-time
                [hourFormat]="authUser.hourFormat"
                [isFullDay]="booking.isDayPass"
                [startTime]="booking.bookingStartTime"
                [endTime]="booking.bookingEndTime"
                [timeZone]="booking.workspace.address?.timeZone"
              >
              </db-booking-start-end-time>
            </div>
          }
        </div>
        <div class="right">
          <div class="action">
            @if (userSpace.bookings?.length || userSpace.isOccupied) {
              @if (userSpace.isOccupied) {
                <div class="small-text label-occupied">
                  @if (userSpace.isOccupied) {
                    <span
                      class="tooltip-occupied"
                      [pTooltip]="occupiedToolTipMessage"
                      tooltipPosition="top"
                    >
                    </span>
                  }
                  <ng-container
                    i18n="@@booking-module|your-spaces|space-occupied"
                    >Occupied</ng-container
                  >
                </div>
              } @else {
                <db-booking-status
                  [booking]="userSpace.bookings![0]"
                  [authUser]="authUser"
                  [dataTestIdForCheckInCta]="
                    bookingStatusDataTestIdForCheckInCta || undefined
                  "
                  (bookingCheckInConfirmation)="
                    bookingCheckInHandler(userSpace.bookings![0])
                  "
                >
                </db-booking-status>
              }
            } @else {
              @if (
                !(
                  userSpace.zoneItemId
                  | isSpaceBeingBooked
                    : (userSpaceIdentifiers || [])[i]
                    : resourcesBeingBooked
                )
              ) {
                <a
                  *dbFeatureAccess="FeatureAccessType.RESOURCE_BOOKING"
                  class="book-cta small-text-bold"
                  data-testid="common--user-spaces-cards-quick-book"
                  [ngClass]="{ disabled: disableQuickBook }"
                  i18n="@@booking-module|your-spaces|quick-book"
                  (click)="quickBookHandler(userSpace)"
                  >Quick book</a
                >
              } @else {
                <ngx-skeleton-loader
                  [theme]="{ margin: '4px 0 0', width: '88px' }"
                ></ngx-skeleton-loader>
              }
            }
          </div>
          @if (showMoreActionsMenu) {
            <div class="menu-trigger" (click)="extraMenu.toggle($event)">
              <i class="pi pi-ellipsis-v"></i>
            </div>
            <db-menu
              #extraMenu
              [popup]="true"
              [items]="extraActionsMenuItems"
              (onShow)="regenerateExtraActionsMenuItems(userSpace)"
            ></db-menu>
          }
        </div>
      </div>
    }
  </div>
} @else {
  <div class="skeleton-loaders-container user-spaces">
    <div>
      <div class="card">
        <div class="left">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              margin: '0px',
              height: '15px',
              position: 'relative',
              bottom: '-4px',
            }"
          >
          </ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ width: '90px', margin: 0, height: '10px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="right">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              height: '20px',
              margin: '0px',
              position: 'relative',
              top: '-1px',
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div>
      <div class="card">
        <div class="left">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              margin: '0px',
              height: '15px',
              position: 'relative',
              bottom: '-4px',
            }"
          >
          </ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ width: '90px', margin: 0, height: '10px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="right">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              height: '20px',
              margin: '0px',
              position: 'relative',
              top: '-1px',
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div>
      <div class="card">
        <div class="left">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              margin: '0px',
              height: '15px',
              position: 'relative',
              bottom: '-4px',
            }"
          >
          </ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ width: '90px', margin: 0, height: '10px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="right">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              height: '20px',
              margin: '0px',
              position: 'relative',
              top: '-1px',
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
}
