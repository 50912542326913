<ng-template #data>
  <ng-container *ngIf="!guestUser; else guestUserTemplate">
    <div
      class="db-container"
      [ngClass]="{
        clickable: canOpenProfileRowOnClick,
        'full-width': fullWidth,
      }"
      [ngStyle]="{ padding: containerPadding }"
      (click)="
        this.userAvatarComponent && canOpenProfileRowOnClick
          ? openProfileHandler($event)
          : null
      "
      [pTooltip]="rowTooltip"
      [attr.data-testid]="containerDataTestId"
      tooltipPosition="above"
    >
      @if (!hideAvatar) {
        <db-user-avatar
          [avatarSize]="avatarSize"
          [showFavUserIcon]="showFavUserIcon"
          [userInfo]="userInfo!"
          [ngStyle]="{ 'margin-right': onlyImage ? '0px' : '10px' }"
          [openProfileOnClick]="openProfileOnClick"
          [authUser]="authUser"
          [showTooltips]="!canOpenProfileRowOnClick"
          [isAnonymous]="isAnonymous"
          [attr.data-testid]="avatarDataTestId"
          (profileOpening)="profileOpening.emit($event)"
        >
        </db-user-avatar>
      }
      <div
        *ngIf="!onlyImage"
        [ngClass]="
          isUserEmailOnSameLine
            ? 'flex items-center'
            : 'flex justify-center flex flex-col'
        "
      >
        <div
          class="name"
          [ngClass]="allUserNameClasses"
          [ngStyle]="userNameStyles"
        >
          <ng-container *ngIf="userInfo | userName as name; else missingName">
            {{ trimmedName ? name.trim().substring(0, 15) + "..." : name }}
          </ng-container>
          <ng-template #missingName>
            <ng-container *ngIf="showEmailIfNameNotAvailable">
              {{ userInfo?.email }}
            </ng-container>
          </ng-template>
          <span
            id="inactive"
            *ngIf="showInactive && userInfo?.status === UserStatus.Inactive"
            >(inactive)</span
          >
        </div>
        <div
          *ngIf="showUserEmail"
          class="email very-small-text"
          [title]="userInfo?.email"
          [ngClass]="{
            'text-dark-gray': isUserEmailLighterGray,
            'ignore-max-width': ignoreEmailMaxWidth,
          }"
        >
          {{ userInfo?.email }}
        </div>
        <div
          *ngIf="showExtra"
          class="extra very-small-text"
          [ngClass]="{ 'very-small-text-bold': isExtraInfoBold }"
          [ngStyle]="extraInfoStyles"
        >
          {{ userInfo?.extra }}
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #guestUserTemplate>
  <div
    class="db-container"
    [ngClass]="{
      'full-width': fullWidth,
    }"
    [ngStyle]="{ padding: containerPadding }"
  >
    <ng-container *ngIf="!hideAvatar">
      <db-guest-avatar
        [avatarSize]="avatarSize"
        [ngStyle]="{ 'margin-right': onlyImage ? '0px' : '10px' }"
      >
      </db-guest-avatar>
    </ng-container>
    <div
      *ngIf="!onlyImage"
      [ngClass]="isUserEmailOnSameLine ? 'flex items-center' : ''"
      [ngClass]="guestDetailsClasses"
    >
      <div
        class="name"
        [ngClass]="allUserNameClasses"
        [ngStyle]="userNameStyles"
      >
        @if (guestUser?.firstName || guestUser?.lastName) {
          {{ guestUser?.firstName }}
          {{ guestUser?.lastName }}

          {{ guestLabel }}
        } @else if (showEmailIfNameNotAvailable) {
          {{ guestUser?.email }}
        }
      </div>
      @if (
        showUserEmail &&
        !(
          showEmailIfNameNotAvailable &&
          !guestUser?.firstName &&
          !guestUser?.lastName
        )
      ) {
        <div
          class="email very-small-text"
          [title]="guestUser?.email"
          [ngClass]="{
            'text-dark-gray': isUserEmailLighterGray,
            'ignore-max-width': ignoreEmailMaxWidth,
          }"
        >
          {{ guestUser?.email }}
        </div>
      }
      @if (showExtra) {
        <div
          class="extra very-small-text"
          [ngClass]="{ 'very-small-text-bold': isExtraInfoBold }"
          [ngStyle]="extraInfoStyles"
        >
          {{ guestUser?.extra }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <div class="skeleton-loader-container">
    <db-skeleton-loader
      *ngIf="onlyImage"
      [skeletonType]="SkeletonLoaderType.CIRCLE"
    >
    </db-skeleton-loader>
    <db-skeleton-loader
      *ngIf="!onlyImage"
      [skeletonType]="
        showUserEmail
          ? SkeletonLoaderType.CIRCLE_AND_DEFAULT_TWO_LINES
          : SkeletonLoaderType.CIRCLE_AND_DEFAULT
      "
      skeletonWidth="80px"
    >
    </db-skeleton-loader>
  </div>
</ng-template>

<ng-container *ngIf="isLoading; then loader; else data"></ng-container>
