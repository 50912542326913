<div
  class="menu-item"
  [ngClass]="{
    active:
      (workspaceRouterLinkActive.isActive || (hasActiveChild$ | async)) &&
      !disabled,
    disabled: disabled,
    loading: loading,
  }"
>
  <a
    class="parent-link"
    [routerLink]="link"
    [skipLocationChange]="skipLocationChange"
    [ngClass]="{ lone: !childMenuItems.length }"
    [routerLinkActive]="link ? 'active' : ''"
    [routerLinkActiveOptions]="linkActiveOptions"
    [queryParamsHandling]="queryParamsHandling"
    #workspaceRouterLinkActive="routerLinkActive"
  >
    @if (imgSrc && showIcon) {
      <img [src]="imgSrc" alt="<image>" />
    }
    <div class="info">
      <span class="title small-text-bold">{{ title }}</span>
    </div>
    @if (badge) {
      <db-menu-badge [text]="badge"></db-menu-badge>
    }
  </a>
  @if (childMenuItems.length) {
    <div class="menu-item-children">
      <ng-content></ng-content>
    </div>
  }
</div>
