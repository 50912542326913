@if (!showOnlyStatusIcon && canSeeStatus) {
  <div
    class="status"
    [ngClass]="{ 'flex-end': alignContentEnd }"
    [pTooltip]="bookingStatusDto.tooltipText"
    [tooltipPosition]="'top'"
  >
    <div class="status-row">
      <img [src]="bookingStatusDto.iconSrc" alt="icon" />
      @if (booking.checkInStatus === "readyForNonQRCheckIn") {
        <button
          class="check-in-cta text-primary small-text-bold"
          [attr.data-testid]="dataTestIdForCheckInCta"
          (click)="checkInHandler($event)"
        >
          {{ bookingStatusDto.labelText }}
        </button>
      } @else {
        <span class="small-text">{{ bookingStatusDto.labelText }}</span>
      }
    </div>
    @if (bookingStatusDto.additionalInfoText) {
      <div class="status-row">
        <span class="very-small-text text-dark-gray">
          {{ bookingStatusDto.additionalInfoText }}
        </span>
      </div>
    }
  </div>
} @else {
  <img [src]="bookingStatusDto.iconSrc" alt="icon" />
}
