@if (showMobileBlocker && !blockerHidden) {
  <div class="db-container">
    <div class="logo">
      <img src="assets/images/deskbird-logo.svg" alt="deskbird" />
    </div>
    <div class="content">
      <div class="text">
        <h4 class="title">A better experience</h4>
        @if (!(isTeamsAppInitialized$ | async)) {
          <span
            class="details normal-text"
            i18n="@@mobile-blocker|web-description"
          >
            For the best deskbird experience, try out our mobile app.</span
          >
        }
      </div>
      <div class="controls">
        <db-button
          width="full-width"
          (click)="openApp()"
          i18n="@@confirmations|mobile|get-deskbird-mobile"
        >
          Get deskbird mobile app
        </db-button>
        @if (!(isTeamsAppInitialized$ | async)) {
          <db-button
            width="full-width"
            buttonType="outlined"
            i18n="@@confirmations|mobile|stay-on-web"
            (click)="blockerHidden = true"
          >
            Continue to deskbird web
          </db-button>
        }
      </div>
    </div>
  </div>
}
