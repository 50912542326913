<p-inputMask
  class="w-full"
  [mask]="mask"
  [characterPattern]="characterPattern"
  [type]="type"
  [slotChar]="slotChar"
  [autoClear]="autoClear"
  [showClear]="showClear"
  [required]="required"
  [disabled]="disabled"
  [ngModel]="value"
  [autoClear]="autoClear"
  (ngModelChange)="valueChangedHandler($event)"
></p-inputMask>
