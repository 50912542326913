import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
  OnChanges,
  SimpleChanges,
  ContentChildren,
  QueryList,
} from "@angular/core";
import { NgTemplateOutlet } from "@angular/common";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { DropdownModule } from "primeng/dropdown";
import { PrimeTemplate } from "primeng/api";

@Component({
  selector: "db-dropdown-with-search",
  standalone: true,
  imports: [NgTemplateOutlet, DropdownModule, DropdownComponent, FormsModule],
  templateUrl: "./dropdown-with-search.component.html",
  styleUrls: ["./dropdown-with-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownWithSearchComponent,
      multi: true,
    },
  ],
})
export class DropdownWithSearchComponent<TItem extends { [key: string]: any }>
  implements ControlValueAccessor, OnChanges
{
  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;
  @ViewChild(DropdownComponent, { read: DropdownComponent, static: true })
  dropdownComponent!: DropdownComponent<TItem>;
  @Input() options: TItem[] = [];
  @Input() optionValue: string = "value";
  @Input() optionLabel!: string;
  @Input() optionDisabled?: string; // Name of the disabled field of an option.
  @Input() selectedOption!: TItem | string | undefined | null;
  @Input() filterBy!: string;
  @Input() virtualScrollItemSize = 44;
  @Input() isDisabled = false;
  @Input() isReadonly = false;
  @Input() placeholder = $localize`:@@common|select-one:Select one`;
  @Input() label: string | undefined;
  @Input() dataTestId?: string;
  @Input()
  emptyFilterMessage = $localize`:@@common|no-results-found:No results found`;
  @Input() styleClass?: string; // Style class of the element.
  @Input() panelStyleClass?: string; // Style class of the overlay panel element.
  @Input() showClear?: boolean; // When enabled, a clear icon is displayed to clear the value.

  @Output() selectedValueChanged = new EventEmitter<string>();

  private changeDetectorRef = inject(ChangeDetectorRef);

  selectedOptionValue: string | null = null;
  private emitRequired = false;

  show = () => {
    this.dropdownComponent.show();
  };
  hide = () => {
    this.dropdownComponent.hide();
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedOption"] || changes["optionValue"]) {
      const value = changes["selectedOption"]?.currentValue;
      const optionValue =
        changes["optionValue"]?.currentValue || this.optionValue;

      if (value) {
        if (typeof value === "string") {
          this.selectedOptionValue = value;
        } else {
          this.selectedOptionValue = value[optionValue] as string;
        }
      } else {
        this.selectedOptionValue = null;
      }
    }
  }

  onFilter = (dd: DropdownComponent<TItem>) => {
    // https://github.com/primefaces/primeng/issues/12461
    if (dd.primeDropdown.scroller) {
      dd.primeDropdown.scroller.contentEl.style.transform =
        "translate3d(0px, 0px, 0px)";
    }
  };

  getSelectedItem = (selectedValue: string) => {
    const item = this.options?.find((item) => {
      if (typeof item === "string") {
        return item === selectedValue;
      } else {
        return item[this.optionValue as keyof typeof item] === selectedValue;
      }
    });
    return item;
  };

  selectedItemHandler({
    value,
    originalEvent,
  }: {
    value: string;
    originalEvent: Event;
  }): void {
    this.selectedOptionValue = value;
    if (originalEvent instanceof KeyboardEvent) {
      return void (this.emitRequired = true);
    }
    this.selectedValueChanged.emit(this.selectedOptionValue);
  }

  onDropdownHideHandler(): void {
    if (!this.emitRequired && !!this.selectedOptionValue) {
      return;
    }
    this.selectedValueChanged.emit(this.selectedOptionValue!);
    this.dropdownComponent.primeDropdown.filterValue = "";
    this.changeDetectorRef.detectChanges();
  }

  writeValue(obj: any): void {
    this.selectedOptionValue = obj;
    this.dropdownComponent.writeValue(obj);
    this.changeDetectorRef.detectChanges();
  }
  registerOnChange(fn: any): void {
    this.dropdownComponent.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.dropdownComponent.registerOnTouched(fn);
  }
  setDisabledState(isDisabled: boolean): void {
    this.dropdownComponent.setDisabledState(isDisabled);
    this.changeDetectorRef.detectChanges();
  }
}
