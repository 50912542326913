import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { SplitButtonModule } from "primeng/splitbutton";
import { MenuItem, SharedModule } from "primeng/api";
import { ButtonComponent } from "../button/button.component";

const multiOfficeClosedTooltipMessage = $localize`:@@planning-module|office-select-button|primary-closed-but-others-are-open:Primary office is closed but you can select another one from the list`;
const singleOfficeClosedTooltipMessage = $localize`:@@planning-module|daily-view|office-closed:Office closed`;

@Component({
  selector: "db-office-select-button",
  standalone: true,
  imports: [CommonModule, SplitButtonModule, ButtonComponent, SharedModule],
  templateUrl: "./office-select-button.component.html",
  styleUrls: ["./office-select-button.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeSelectButtonComponent {
  otherOfficesList = input<MenuItem[]>([]);
  rejected = input<string | null>();
  isPrimaryRejected = input<boolean>(false);

  @Input() primaryOfficeButtonLabel = "";
  @Input() isOfficeClosed = false;
  @Input() isBlocked = false;
  @Output() primaryOfficeSelect = new EventEmitter<void>();

  shouldShowRejectedIcon = computed(() => {
    return this.otherOfficesList()
      ? this.otherOfficesList()?.some((office) => office.id === this.rejected())
      : false;
  });

  get isMultiOfficeClosedTooltipMessage(): string {
    return this.isOfficeClosed ? multiOfficeClosedTooltipMessage : "";
  }

  get isSingleOfficeClosedTooltipMessage(): string {
    return this.isOfficeClosed ? singleOfficeClosedTooltipMessage : "";
  }

  primaryOfficeSelectHandler() {
    if (this.isOfficeClosed) {
      return;
    }
    this.primaryOfficeSelect.emit();
  }
}
