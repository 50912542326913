<button
  pButton
  pRipple
  [type]="isSubmit ? 'submit' : 'button'"
  [ngStyle]="style"
  [ngClass]="{
    'p-button-outlined': buttonType === 'outlined',
    'p-button-icon-inverse': iconPos === 'right',
    bold: isBold,
    'is-loading': isLoading,
    'fit-content': width === 'fit-content',
    'w-full': width === 'full-width',
    'min-width-160': width === 'min-width-160',
    'p-button-secondary': severity === 'secondary',
    'p-button-danger': severity === 'danger',
    white: severity === 'white',
    'button-sm': size === 'small',
  }"
  [disabled]="disabled"
  [icon]="icon"
  [iconPos]="iconPos"
  [attr.data-testId]="dataTestId"
  [loading]="isLoading"
>
  <ng-content></ng-content>
</button>
