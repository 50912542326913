import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CommonModule } from "@angular/common";
import { UserGroupAvatarComponent } from "../../user-group-avatar";
import { UserInfoComponent } from "../../user-info";
import { Observable, of, take } from "rxjs";
import { IUserGroupSearchResult, IUserInfo, SimpleChangesTyped } from "types";
import {
  FormSelectionItem,
  SelectionItem,
} from "./../autocomplete-users.types";
import { AutocompleteUsersComponentBase } from "./../autocomplete-users-base";

@Component({
  selector: "db-autocomplete-users-single-select",
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    UserInfoComponent,
    UserGroupAvatarComponent,
  ],
  templateUrl: "./autocomplete-users-single-select.component.html",
  styleUrl: "./autocomplete-users-single-select.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AutocompleteUsersSingleSelectComponent,
      multi: true,
    },
  ],
})
/**
 * AutocompleteUsersSingleSelectComponent provides two interfaces for single user/group selection:
 *
 * 1. Direct Usage Interface:
 *    - Uses initiallySelected* inputs (initiallySelectedUserId, initiallySelectedGroupId)
 *    - Emits full SelectionItem objects via selectionChange output
 *    - Contains complete user/group information in the emitted data
 *    - Best for simple scenarios where you need the full user/group data immediately
 *
 * 2. Reactive Forms Interface:
 *    - Uses FormSelectionItem which only contains IDs/emails
 *    - Lighter weight for form state management
 *    - Automatically loads full user/group data when form value is set
 *    - Best for forms where you only need to store IDs/emails
 *
 * Example of Direct Usage:
 * ```html
 * <db-autocomplete-users-single-select
 *   [initiallySelectedUserId]="10"
 *   [initiallySelectedGroupId]="20"
 *   (selectionChange)="onSelectionChange($event)"
 * />
 * ```
 *
 * Example of Reactive Forms Usage:
 * ```html
 * <db-autocomplete-users-single-select
 *   formControlName="selection"
 * />
 * ```
 * ```typescript
 * form = new FormGroup({
 *   selection: new FormControl<FormSelectionItem>(null),
 * });
 * ```
 */
export class AutocompleteUsersSingleSelectComponent
  extends AutocompleteUsersComponentBase
  implements OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() initiallySelectedUserId: string | null = null;
  @Input() initiallySelectedGroupId: string | null = null;
  @Input() override dataTestId: string = "autocomplete-users-single-select";
  @Input() override filterDeskAreaId: string = "";
  @Input() override showClear: boolean = false;

  @Output() selectionChange = new EventEmitter<SelectionItem | null>();

  private initialUserSelectionTriggered = false;
  private initialGroupsSelectionTriggered = false;
  protected override allowMultipleSelectedItems: boolean = false;

  ngOnChanges(
    changes: SimpleChangesTyped<AutocompleteUsersSingleSelectComponent>,
  ): void {
    if (changes.initiallySelectedUserId || changes.initiallySelectedGroupId) {
      this.initializeSelectedItems();
    }
  }

  writeValue(value: FormSelectionItem | null): void {
    if (value === null) {
      this.currentSelection = [];
      this.selectionChange.emit(
        this.currentSelection.length ? this.currentSelection[0] : null,
      );
      this.cdRef.markForCheck();
      return;
    }

    const loadItemsOperator: Observable<
      IUserInfo[] | IUserGroupSearchResult[]
    > =
      value.type === "user"
        ? this.loadUsersById([value.id])
        : value.type === "group"
          ? this.loadGroupsById([value.id])
          : of([]);

    loadItemsOperator.pipe(take(1)).subscribe((items) => {
      this.currentSelection = items.length
        ? value.type === "user"
          ? [this.mapUserToSelectionItem(items[0] as IUserInfo)]
          : value.type === "group"
            ? [this.mapGroupToSelectionItem(items[0] as IUserGroupSearchResult)]
            : []
        : [];
      this.selectionChange.emit(
        this.currentSelection ? this.currentSelection[0] : null,
      );
      this.cdRef.markForCheck();
    });
  }

  registerOnChange(fn: (value: FormSelectionItem | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  protected override addSuggestions(
    users: IUserInfo[],
    groups: IUserGroupSearchResult[],
    _searchQuery: string,
  ): SelectionItem[] {
    const suggestedUsers = users.map(this.mapUserToSelectionItem.bind(this));
    const suggestedGroups = groups.map(this.mapGroupToSelectionItem.bind(this));

    return [...suggestedGroups, ...suggestedUsers];
  }

  protected emitCurrentSelection(): void {
    // For direct usage via selectionChange output
    this.selectionChange.emit(
      this.currentSelection.length ? this.currentSelection[0] : null,
    );

    // For reactive forms - convert SelectionItem to FormSelectionItem
    let formValue: FormSelectionItem | null = null;
    if (this.currentSelection.length) {
      const selection = this.currentSelection[0];
      switch (selection.type) {
        case "user":
          formValue = {
            type: "user" as const,
            id: this.useUuid ? selection.user.uuid! : selection.user.id!,
          };
          break;
        case "group":
          formValue = {
            type: "group" as const,
            id: this.useUuid ? selection.group.uuid : selection.group.id,
          };
          break;
        case "externalEmail":
          throw new Error(
            "External email is not implemented for single select",
          );
      }
    } else {
      formValue = null;
    }
    this.onChange(formValue);
    this.onTouched();

    window.setTimeout(() => {
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    }, 50);
  }

  private onChange: (value: FormSelectionItem | null) => void = () => {};
  private onTouched: () => void = () => {};

  private initializeSelectedItems(): void {
    this.initializeSelectedUsers();
    this.initializeSelectedGroups();

    this.emitCurrentSelection();
  }

  private initializeSelectedUsers(): void {
    if (
      this.initiallySelectedUserId &&
      !this.initialUserSelectionTriggered &&
      this.companyId &&
      this.enableUserSelection
    ) {
      this.initialUserSelectionTriggered = true;
      this.loadUsersById([this.initiallySelectedUserId]).subscribe((users) => {
        this.currentSelection = users.length
          ? [this.mapUserToSelectionItem(users[0])]
          : [];
        this.emitCurrentSelection();
      });
    }
  }

  private initializeSelectedGroups(): void {
    if (
      this.initiallySelectedGroupId &&
      !this.initialGroupsSelectionTriggered &&
      this.enableGroupSelection
    ) {
      this.initialGroupsSelectionTriggered = true;
      this.loadGroupsById([this.initiallySelectedGroupId]).subscribe(
        (groups) => {
          this.currentSelection = groups.length
            ? [this.mapGroupToSelectionItem(groups[0])]
            : [];
          this.emitCurrentSelection();
        },
      );
    }
  }
}
