<db-dropdown
  [ngClass]="class"
  [options]="dropdownOptions"
  [selectedValue]="selectedValue"
  (selectedValueChanged)="selectedValueChanged.emit($event)"
>
  <ng-template let-selectedItem pTemplate="selectedItem">
    <ng-container
      *ngTemplateOutlet="itemTemplate; context: { item: selectedItem }"
    />
  </ng-template>
  <ng-template let-item pTemplate="item">
    <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }" />
  </ng-template>
</db-dropdown>

<ng-template let-item="item" #itemTemplate>
  <div class="flex items-center">
    @if (item.value === DeskAreaType.FlexDesk) {
      <img src="/assets/icons/resources-lavender.svg" />
    } @else if (item.value === DeskAreaType.Parking) {
      <img src="/assets/icons/parking-spots-pink.svg" />
    } @else if (item.value === DeskAreaType.Other) {
      <img src="/assets/icons/other-resources-gray.svg" />
    } @else if (item.value === DeskAreaType.MeetingRoom) {
      <img src="/assets/icons/meeting-room-area-brown.svg" />
    }
    <span class="small-text ml-xl">{{ item.label }}</span>
  </div>
</ng-template>
