export enum FeatureFlag {
  ENABLE_BOOKING_FOR_GUESTS = "DEV_ENABLE_BOOKING_FOR_GUESTS",
  ENABLE_SCHEDULING_CALENDAR_SYNC = "ENABLE_SCHEDULING_CALENDAR_SYNC",
  ENABLE_BOOKING_LIMIT_50 = "DEV_ENABLE_BOOKING_LIMIT_50",
  ENABLE_HYBRID_WORK_POLICIES_PREVIEW = "ENABLE_HYBRID_WORK_POLICIES_PREVIEW",
  ENABLE_FOLLOW_USER_BUTTON = "ENABLE_FOLLOW_USER_BUTTON",
  ENABLE_DAILY_OFFICE_TABLE = "ENABLE_DAILY_OFFICE_TABLE",
  ENABLE_ASSISTANT = "DEV_ENABLE_ASSISTANT",
  HIDE_ICONS_IN_MENU = "HIDE_ICONS_IN_MENU",
  ENABLE_CUSTOM_ANALYTICS = "ENABLE_CUSTOM_ANALYTICS",
  ENABLE_OFFICE_EQUIPMENT = "ENABLE_OFFICE_EQUIPMENT",
  ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT = "ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT",
  ENABLE_PUBIC_API_KEYS = "ENABLE_PUBIC_API_KEYS",
  ENABLE_AREA_EXTENDED_NAME_AND_DESCRIPTION = "ENABLE_AREA_EXTENDED_NAME_AND_DESCRIPTION",
  ENABLE_MY_TEAMS_MANAGEMENT = "ENABLE_MY_TEAMS_MANAGEMENT",
  ENABLE_MPPS = "DEV_ENABLE_MPPS",
  ENABLE_MPPS_MOCKS = "DEV_ENABLE_MPPS_MOCKS",
  ENABLE_CATERING_AND_SERVICES = "ENABLE_CATERING_AND_SERVICES",
  ENABLE_KIOSK_MODE = "ENABLE_KIOSK_MODE",
}
