import { IUserInfo } from "types/interfaces/user-info";
import { IUserGroupSearchResult } from "types/interfaces/users/user-group-search-result";

/**
 * FormSelectionItem is used for reactive forms integration.
 * It contains only the minimal information needed to identify a selection.
 */
export type FormSelectionItem =
  | {
      type: "user";
      id: string;
    }
  | {
      type: "group";
      id: string;
    }
  | {
      type: "externalEmail";
      email: string;
    };

/**
 * SelectionItem is used for direct component usage with initiallySelected* inputs and selectionChange output.
 * It contains the full information about the selected item.
 */
export type SelectionItem =
  | {
      type: "user";
      key: string;
      user: IUserInfo;
    }
  | {
      type: "group";
      key: string;
      group: IUserGroupSearchResult;
    }
  | {
      type: "externalEmail";
      key: string;
      email: string;
    };
