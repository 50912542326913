import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  inject,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  Observable,
  Subscription,
  asyncScheduler,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  fromEvent,
  map,
  observeOn,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { HiddenDirective, DEFAULT_TIMEZONE } from "shared";
import { IAddressInfo } from "./interfaces";
import {
  areAddressesEqual,
  generateAddressInfo,
  getLatLngFromLocation,
  locateAddress,
  locateCoordinates,
} from "./utils";
import { ProgressSpinnerComponent } from "db-ui";

@Component({
  selector: "db-google-maps-address",
  standalone: true,
  imports: [
    CommonModule,
    GoogleMapsModule,
    ProgressSpinnerComponent,
    HiddenDirective,
  ],
  templateUrl: "./google-maps-address.component.html",
  styleUrls: ["./google-maps-address.component.scss"],
  exportAs: "dbGoogleMapsAddress",
})
export class GoogleMapsAddressComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChildren("googleMap", { read: GoogleMap }) googleMapList:
    | QueryList<GoogleMap>
    | undefined;
  isComponentDestroyed = false;

  get googleMap() {
    return this.googleMapList?.first;
  }

  get google(): typeof window.google | undefined {
    return window.google;
  }

  @Input() inputElement!: HTMLInputElement;
  @Input() height!: string | number | null;
  @Input() width!: string | number | null;
  @Input() officeCoordinates: { lat: number; lng: number } | undefined;

  @Output() addressChanged = new EventEmitter<IAddressInfo>();
  @Output() noResultsFound = new EventEmitter<boolean>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() errorLoadingGoogleApi = new EventEmitter<boolean>();

  static googleMapsJavascriptLoaded = false;

  private http = inject(HttpClient);
  private zone = inject(NgZone);
  private changeDetectorRef = inject(ChangeDetectorRef);

  private apiKey = "AIzaSyCDgzkO_CGqVvvc0z1uojddXJ3HE5i6bC4";
  private setupSchedulerId: number | null = null;

  private promiseList: { res: (args: any) => void; rej: (err: any) => void }[] =
    [];
  private inputElementValueForCurrentSetup: string | null = null;
  private autocomplete!: google.maps.places.Autocomplete;
  private officeMarker: google.maps.Marker | null = null;
  private addressMarker: google.maps.Marker | null = null;
  private officeMarkerConnector: google.maps.Polyline | null = null;
  private inputElementChangeSubscription = new Subscription();
  private subscription = new Subscription();

  // NOTE: When the parent component is using OnPush strategy we need to emit an event
  // in order for the parent view to be marked as dirty and be re-rendered so please
  // use the event emitters in this case

  private _isLoadingCoordinates = false;
  set isLoadingCoordinates(val: boolean) {
    this._isLoadingCoordinates = val;
    this.isLoading.emit(this._isLoadingCoordinates);
    this.changeDetectorRef.detectChanges();
  }
  get isLoadingCoordinates() {
    return this._isLoadingCoordinates;
  }

  _errorLoadingApi = false;
  set errorLoadingApi(val: boolean) {
    this._errorLoadingApi = val;
    this.errorLoadingGoogleApi.emit(this._errorLoadingApi);
  }
  get errorLoadingApi() {
    return this._errorLoadingApi;
  }

  _noResultsFoundFromLastSearch = false;
  set noResultsFoundFromLastSearch(val: boolean) {
    this._noResultsFoundFromLastSearch = val;
    this.noResultsFound.emit(this._noResultsFoundFromLastSearch);
  }
  get noResultsFoundFromLastSearch() {
    return this._noResultsFoundFromLastSearch;
  }

  options: google.maps.MapOptions = {};

  apiMapsApiLoaded$: Observable<boolean> =
    GoogleMapsAddressComponent.googleMapsJavascriptLoaded
      ? of(true)
      : this.http
          .jsonp(
            `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places&language=en`,
            "callback",
          )
          .pipe(
            tap(() => {
              GoogleMapsAddressComponent.googleMapsJavascriptLoaded = true;
            }),
            map(() => true),
            filter(() => !this.isComponentDestroyed),
            catchError((e) => {
              this.errorLoadingApi = true;
              console.error(e);
              return [false];
            }),
            shareReplay(1),
          );

  private setup = (
    resetOfficeLocation = false,
    geocoderResult?: google.maps.GeocoderResult,
    timeZoneId?: string,
  ): Promise<null | IAddressInfo> => {
    if (!this.googleMap || !this.inputElement.value) {
      return Promise.resolve(null);
    }
    this.isLoadingCoordinates = true;

    this.noResultsFoundFromLastSearch = false;
    if (this.setupSchedulerId) {
      clearTimeout(this.setupSchedulerId);
      this.setupSchedulerId = null;
    }
    return new Promise<null | IAddressInfo>((res, rej) => {
      this.promiseList.push({ res, rej });
      this.setupSchedulerId = setTimeout(() => {
        this.inputElementValueForCurrentSetup = geocoderResult
          ? geocoderResult.formatted_address
          : this.inputElement.value;
        (geocoderResult
          ? Promise.resolve(geocoderResult)
          : locateAddress(this.zone, this.inputElementValueForCurrentSetup)
        )
          .then((geocoderResult) => {
            if (!geocoderResult) {
              this.promiseList.forEach(({ res }) => res(null));
              this.promiseList = [];
              return;
            }
            const { lat, lng } = getLatLngFromLocation(
              geocoderResult.geometry.location,
            );
            if (!lat || !lng) {
              this.promiseList.forEach(({ res }) => res(null));
              this.promiseList = [];
              return;
            }
            const addressLatLng =
              (this.google && new this.google.maps.LatLng(lat, lng)) || null;
            if (addressLatLng) {
              this.setupMap(addressLatLng);
            }
            if (resetOfficeLocation) {
              this.resetOfficeLocation(true);
            }

            if (timeZoneId) {
              return Promise.all([geocoderResult, timeZoneId] as const);
            }
            return Promise.all([
              geocoderResult,
              firstValueFrom(this.loadTimezoneFromCoordinates(lat, lng)).then(
                ({ timeZoneId }) => timeZoneId,
              ),
            ] as const);
          })
          .then((result) => {
            if (!result) {
              return;
            }
            const [geocoderResult, timeZoneId] = result;
            this.setupSchedulerId = null;
            const addressInfo = generateAddressInfo(geocoderResult, timeZoneId);
            this.promiseList.forEach(({ res }) => res(addressInfo));
            this.promiseList = [];
          })
          .catch((error) => {
            console.error(error);
            if (error.code === "ZERO_RESULTS") {
              this.noResultsFoundFromLastSearch = true;
            }
            this.promiseList.forEach(({ rej }) => rej(error));
            this.promiseList = [];
          })
          .finally(() => {
            this.isLoadingCoordinates = false;
          });
      }) as unknown as number;
    });
  };

  private loadTimezoneFromCoordinates = (
    lat: number | string | null,
    lng: number | string | null,
  ) => {
    if (lat === null || lng === null) {
      return of({ timeZoneId: DEFAULT_TIMEZONE });
    }
    const url = `https://maps.googleapis.com/maps/api/timezone/json?key=${this.apiKey}&location=${lat},${lng}&timestamp=0`;
    return this.http.get<{ timeZoneId: string }>(url);
  };

  public resetOfficeLocation = (skipChangeEmit = false): void => {
    this.officeMarkerConnector!.setPath([]);
    this.officeMarker!.setPosition(this.addressMarker!.getPosition());
    this.officeMarker?.setIcon("/assets/icons/red-pin.svg");
    const addressPosition = this.addressMarker!.getPosition()!;
    this.googleMap?.panTo(addressPosition);
    this.isLoadingCoordinates = true;
    locateAddress(this.zone, this.inputElementValueForCurrentSetup!)
      .then((geocoderResult) => {
        if (!geocoderResult) {
          return null;
        }
        const { lat, lng } = getLatLngFromLocation(
          geocoderResult.geometry.location,
        );
        return firstValueFrom(this.loadTimezoneFromCoordinates(lat, lng)).then(
          ({ timeZoneId }) => ({ timeZoneId, geocoderResult }),
        );
      })
      .then((result) => {
        if (!result) {
          return;
        }
        const { geocoderResult, timeZoneId } = result;
        const addressInfo = generateAddressInfo(geocoderResult, timeZoneId);
        if (skipChangeEmit) {
          return;
        }
        this.addressChanged.emit(addressInfo);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isLoadingCoordinates = false;
      });
  };

  private setupMarkers = (addressCoordinates: google.maps.LatLng): void => {
    const { lat, lng } = getLatLngFromLocation(addressCoordinates);

    const areOfficeCoordinatesDifferent =
      this.officeCoordinates &&
      this.officeCoordinates.lat !== lat &&
      this.officeCoordinates.lng !== lng;

    this.addressMarker =
      (this.google &&
        new this.google.maps.Marker({
          map: this.googleMap!.googleMap,
          draggable: false,
          icon: "/assets/icons/red-pin.svg",
          zIndex: 1000,
        })) ||
      null;

    this.officeMarker =
      (this.google &&
        new this.google.maps.Marker({
          map: this.googleMap!.googleMap,
          draggable: true,
          icon: areOfficeCoordinatesDifferent
            ? "/assets/icons/blue-pin.svg"
            : "/assets/icons/red-pin.svg",
          zIndex: areOfficeCoordinatesDifferent ? 1001 : 1000,
        })) ||
      null;

    this.officeMarkerConnector =
      (this.google &&
        new this.google.maps.Polyline({
          map: this.googleMap!.googleMap,
          draggable: true,
        })) ||
      null;

    const officeCoordinates =
      (this.google &&
        new this.google.maps.LatLng(
          this.officeCoordinates!.lat,
          this.officeCoordinates!.lng,
        )) ||
      null;

    this.officeMarker?.setPosition(officeCoordinates);
    this.addressMarker?.setPosition(addressCoordinates);

    if (addressCoordinates && officeCoordinates) {
      this.officeMarkerConnector?.setPath([
        addressCoordinates,
        officeCoordinates,
      ]);
    }

    if (this.officeMarker) {
      this.google?.maps.event.addListener(
        this.officeMarker,
        "drag",
        (ev: any) => {
          if (this.officeMarker!.getIcon() !== "/assets/icons/blue-pin.svg") {
            this.officeMarker!.setIcon("/assets/icons/blue-pin.svg");
          }
          this.officeMarkerConnector?.setPath([addressCoordinates, ev.latLng]);
        },
      );
      this.google?.maps.event.addListener(
        this.officeMarker,
        "dragend",
        (ev: any) => {
          Promise.all([
            locateCoordinates(
              this.zone,
              addressCoordinates.lat(),
              addressCoordinates.lng(),
            ),
            firstValueFrom(
              this.loadTimezoneFromCoordinates(
                addressCoordinates.lat(),
                addressCoordinates.lng(),
              ),
            ),
          ])
            .then(([geocoderResult, { timeZoneId }]) => {
              if (!geocoderResult) {
                return;
              }
              const addressInfo = generateAddressInfo(
                geocoderResult,
                timeZoneId,
              );
              addressInfo.coordinates.lat = ev.latLng.lat();
              addressInfo.coordinates.lng = ev.latLng.lng();
              this.addressChanged.emit(addressInfo);
            })
            .catch((error) => console.error(error));
        },
      );
    }
  };

  private clearMarkers = (): void => {
    if (this.google && this.officeMarker) {
      this.google.maps.event.clearListeners(this.officeMarker, "dragend");
      this.google.maps.event.clearListeners(this.officeMarker, "drag");
    }
    this.officeMarker?.setMap(null);
    this.addressMarker?.setMap(null);
    this.officeMarkerConnector?.setMap(null);

    this.officeMarkerConnector = null;
    this.officeMarker = null;
    this.addressMarker = null;
  };

  private setupMap = (addressLocation: google.maps.LatLng): void => {
    this.clearMarkers();
    this.setupMarkers(addressLocation);
    const panToLocation =
      this.officeMarker?.getPosition() ||
      this.addressMarker?.getPosition() ||
      addressLocation;
    this.googleMap!.panTo(panToLocation);
  };

  private autocompleteChangeHandler = (): void => {
    const place = this.autocomplete.getPlace();
    const data = {
      address: place.formatted_address,
      coordinates: {
        lng:
          place.geometry && place.geometry.location
            ? place.geometry?.location.lng()
            : 0,
        lat:
          place.geometry && place.geometry.location
            ? place.geometry?.location.lat()
            : 0,
      },
    };

    this.loadTimezoneFromCoordinates(
      data.coordinates.lat,
      data.coordinates.lng,
    ).subscribe(({ timeZoneId }) => {
      const addressInfo = generateAddressInfo(place, timeZoneId);
      this.setup(true).catch(() => {});
      this.officeCoordinates = addressInfo.coordinates;
      this.addressChanged.emit(addressInfo);
    });
  };

  ngAfterViewInit(): void {
    if (!this.inputElement) {
      return void console.error(
        "GoogleMapsAddressComponent: No inputElement provided!",
      );
    }

    this.apiMapsApiLoaded$
      .pipe(
        filter((val) => !!val),
        take(1),
        tap(() => {
          this.zone.runOutsideAngular(() => {
            if (!this.google) {
              return;
            }
            this.autocomplete = new this.google.maps.places.Autocomplete(
              this.inputElement,
              { types: ["address"] },
            );
            this.google.maps.event.addListener(
              this.autocomplete,
              "place_changed",
              () => {
                this.zone.run(() => this.autocompleteChangeHandler());
              },
            );
          });
        }),
        switchMap(() =>
          this.googleMapList!.changes.pipe(
            startWith(this.googleMapList),
            filter((val) => !!val.first),
          ),
        ),
        observeOn(asyncScheduler), // Note: async to prevent expressions changed after it was checked
      )
      .subscribe(() => {
        this.setup().catch(() => {});
      });
  }

  mapClickHandler = ({
    latLng: addressLatLng,
  }: google.maps.MapMouseEvent): void => {
    if (!addressLatLng) {
      return;
    }
    const { lat, lng } = getLatLngFromLocation(addressLatLng);
    if (!lat || !lng) {
      return;
    }

    this.loadTimezoneFromCoordinates(lat, lng)
      .pipe(
        switchMap(({ timeZoneId }) =>
          locateCoordinates(this.zone, lat, lng)
            // Note: use locateAddress after locateCoordinates because locateCoordinates coordinates
            // differ from the ones from locateAddress and this is causing issues
            .then((geocoderResult) =>
              geocoderResult
                ? locateAddress(this.zone, geocoderResult.formatted_address)
                : Promise.resolve(geocoderResult),
            )
            .then((geocoderResult) => ({ geocoderResult, timeZoneId }))
            .catch((error) => console.error(error)),
        ),
      )
      .subscribe((result) => {
        if (!result || !result.geocoderResult) {
          return;
        }
        this.setup(true, result.geocoderResult, result.timeZoneId)
          .then((addressInfo) => {
            if (!addressInfo) {
              return;
            }
            this.addressChanged.emit(addressInfo);
          })
          .catch(() => {});
      });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["inputElement"]) {
      this.inputElementChangeSubscription.unsubscribe();
      this.inputElementChangeSubscription = fromEvent(
        this.inputElement,
        "input",
      )
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(() => {
            if (
              areAddressesEqual(
                this.inputElement.value,
                this.inputElementValueForCurrentSetup,
              )
            ) {
              return [null];
            }
            return this.setup(true)
              .then((addressInfo) => {
                if (!addressInfo) {
                  return null;
                }
                return addressInfo;
              })
              .catch(() => null);
          }),
          filter((addressInfo): addressInfo is IAddressInfo => !!addressInfo),
          switchMap((addressInfo) =>
            fromEvent(this.inputElement, "change").pipe(
              take(1),
              map(() => addressInfo),
            ),
          ),
        )
        .subscribe((addressInfo) => {
          this.addressChanged.emit(addressInfo);
        });
    }
    if (
      changes["officeCoordinates"] &&
      !changes["officeCoordinates"].firstChange
    ) {
      if (this.inputElement) {
        const currentInputElementValue = this.inputElement.value;
        const hasCoordinatesChanges =
          changes["officeCoordinates"].currentValue.lat !==
            changes["officeCoordinates"].previousValue.lat ||
          changes["officeCoordinates"].currentValue.lng !==
            changes["officeCoordinates"].previousValue.lng ||
          (!!this.officeMarker?.getPosition &&
            changes["officeCoordinates"].currentValue.lat !==
              this.officeMarker?.getPosition()?.lat()) ||
          (!!this.officeMarker?.getPosition &&
            changes["officeCoordinates"].currentValue.lng !==
              this.officeMarker?.getPosition()?.lng());

        if (
          !areAddressesEqual(
            currentInputElementValue,
            this.inputElementValueForCurrentSetup,
          )
        ) {
          return void this.setup(!hasCoordinatesChanges).catch(() => {});
        } else if (hasCoordinatesChanges) {
          return void this.setup().catch(() => {});
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.google) {
      this.google.maps.event.clearListeners(this.autocomplete, "place_changed");
    }
    this.inputElementChangeSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.isComponentDestroyed = true;
  }
}
