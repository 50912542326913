import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "db-menu-badge",
  standalone: true,
  imports: [CommonModule],
  template: `<span class="badge">{{ text }}</span>`,
  styleUrls: ["./menu-badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuBadgeComponent {
  @Input() text: string = "";
}
