<button
  class="status-button"
  [ngClass]="styleClasses"
  [ngStyle]="styles"
  [disabled]="isDisabled"
  (click)="statusClickHandler()"
  [attr.data-testId]="dataTestId"
>
  <div class="label-wrapper">
    @if (buttonLabel) {
      <span>{{ buttonLabel }}</span>
    } @else {
      <span>
        <db-daily-status-option-label
          [status]="status"
        ></db-daily-status-option-label>
      </span>
    }
    @if (officeName) {
      <span data-testid="schedule--office-name" class="very-small-text">{{
        officeName
      }}</span>
    }
  </div>
</button>

@if (isManagerIcon && shouldDisplayStatusIcon()) {
  <i
    class="pi pi-clock status-manager-icon"
    [style.color]="status?.color"
    pTooltip="Request is pending approval."
    i18n-pTooltip="@@planning-module|request-pending-approval"
    tooltipPosition="top"
  ></i>
}

@if (rejected && shouldDisplayStatusIcon()) {
  <i
    class="pi pi-exclamation-circle status-manager-icon"
    [style.color]="'red'"
    pTooltip="Your request has been rejected by your manager "
    i18n-pTooltip="@@planning-module|request-rejected-by"
    tooltipPosition="bottom"
  ></i>
}
