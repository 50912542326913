import { Component, Input, ViewEncapsulation } from "@angular/core";
import { ProgressBarModule } from "primeng/progressbar";
import { CommonModule } from "@angular/common";
import { VirtualScrollerModule } from "primeng/virtualscroller";
@Component({
  selector: "db-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [ProgressBarModule, CommonModule, VirtualScrollerModule],
  standalone: true,
})
export class ProgressBarComponent {
  @Input() value: number | undefined = undefined;
  @Input() showValue: boolean = false;
  @Input() mode: "determinate" | "indeterminate" = "indeterminate";
  @Input() borderStyle: "squared" | "rounded" = "squared";
}
