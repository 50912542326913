import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { IUserGroupSearchFilter } from "types";
import { PaginatedResult } from "types/interfaces/paginated-response";
import { IUserGroupSearchResult } from "types/interfaces/users/user-group-search-result";

export class UserGroupFilterService {
  protected readonly http = inject(HttpClient);

  loadGroupsForCompanyFiltered({
    companyId,
    searchQuery,
    groupIds,
    excludeGroupIds,
    include,
    offset,
    limit,
    userStatuses,
  }: IUserGroupSearchFilter): Observable<
    PaginatedResult<IUserGroupSearchResult>
  > {
    const baseUrl = "v2/userGroups";
    let query = `companyId=${companyId}&offset=${offset}&limit=${limit}`;

    if (searchQuery) {
      query = query.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`);
    }

    if (groupIds?.length) {
      query = query.concat(`&groupIds=${groupIds.join(",")}`);
    }

    if (excludeGroupIds?.length) {
      query = query.concat(`&excludeGroupIds=${excludeGroupIds.join(",")}`);
    }

    if (include?.length) {
      query = query.concat(`&include=${include.join(",")}`);

      if (userStatuses?.length) {
        query = query.concat(`&userStatuses=${userStatuses.join(",")}`);
      }
    }

    return this.http.get<PaginatedResult<IUserGroupSearchResult>>(
      `/api/${baseUrl}?${query}`,
    );
  }
}
