import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResult } from "types/interfaces/paginated-response";
import { ILoadUsersFilteredRequest_v3 } from "types/interfaces/user-filtered-request";
import { IUserInfo } from "types/interfaces/user-info";
import { IUserFilterService as IUserFilterService } from "./user-filter.interface";
import { UserGroupFilterService } from "./user-group-filter.service";

@Injectable({
  providedIn: "root",
})
export class BookableUserFilterService
  extends UserGroupFilterService
  implements IUserFilterService
{
  loadUsersForCompanyFiltered({
    searchQuery,
    primaryOfficeIds,
    roles,
    userGroups,
    onlyUsersWithNoGroups,
    officeRolesIds,
    followStatus,
    userIds,
    excludeUserIds,
    status,
    sortField,
    sortOrder,
    offset,
    limit,
    include,
    deskAreaId,
  }: ILoadUsersFilteredRequest_v3): Observable<PaginatedResult<IUserInfo>> {
    if (
      primaryOfficeIds ||
      roles ||
      userGroups ||
      onlyUsersWithNoGroups ||
      officeRolesIds ||
      followStatus ||
      status ||
      include
    ) {
      throw new Error("Filter not implemented in bookableUsers endpoint");
    }

    if (!offset || !limit) {
      throw new Error(
        "Offset and limit are required for utilizing the backend pagination",
      );
    }

    const params = new URLSearchParams();
    params.set("offset", offset.toString());
    params.set("limit", limit.toString());

    if (sortField) {
      params.set("sortField", sortField);
    }

    if (sortOrder) {
      params.set("sortOrder", sortOrder);
    }

    if (searchQuery) {
      params.set("searchQuery", searchQuery);
    }

    if (userIds?.length) {
      params.set("userIds", userIds.join(","));
    }

    if (excludeUserIds?.length) {
      params.set("excludeUserIds", excludeUserIds.join(","));
    }

    return this.http.get<PaginatedResult<IUserInfo>>(
      `/api/zones/${deskAreaId}/bookableUsers?${params.toString()}`,
    );
  }
}
