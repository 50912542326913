/*
 * Public API Surface of notification-module
 */
import "@angular/localize/init";

export * from "./lib/notification/notification.component";
export * from "./lib/notification.module";
export * from "./lib/+store/model";
export { NotificationType } from "./lib/enums";
export { NotificationModel } from "./lib/+store/model";
export { NOTIFICATION_STORE_NAME } from "./lib/constants";
