import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { DailyStatusOptionLabelComponent } from "../daily-status-option-label";
import { IOfficePlanningStatusOption } from "types";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: "db-planning-status-button",
  standalone: true,
  imports: [CommonModule, DailyStatusOptionLabelComponent, TooltipModule],
  templateUrl: "./planning-status-button.component.html",
  styleUrls: ["./planning-status-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanningStatusButtonComponent {
  @Input() buttonLabel: string | undefined = "";
  @Input() isFullWidth = false;
  @Input() isBlocked = false;
  @Input() withRightMargin = false;
  @Input() isDisabled = false;
  @Input() isDisabledWithOpacity = false; // the default disabled style is just gray button so send this flag true to show the status color itself with opacity
  @Input() isSelected = false;
  @Input() isRemovable = true;
  @Input() officeName: string | boolean | undefined = "";
  @Input() colorStyle: "background" | "outline" = "background";
  @Input() status!: IOfficePlanningStatusOption;
  @Input() dataTestId: string | undefined = "";
  @Input() isLabelCentered = false;
  @Input() isManagerIcon = false;
  @Input() rejected: boolean = false;
  @Output() statusClicked = new EventEmitter<void>();

  readonly shouldDisplayStatusIcon = input<boolean>(false);

  statusClickHandler() {
    if (this.isRemovable) {
      this.statusClicked.emit();
    }
  }

  get styles(): { [key: string]: string | null } {
    return {
      color:
        (!this.isDisabled &&
          this.colorStyle === "outline" &&
          this.isSelected &&
          this.status.color) ||
        null,
      "background-color":
        (!this.isDisabled &&
          this.colorStyle === "background" &&
          this.status.color) ||
        null,
      "border-color":
        (!this.isDisabled &&
          (this.colorStyle === "background" || this.isSelected) &&
          this.status.color) ||
        null,
    };
  }

  get styleClasses(): { [key: string]: boolean } {
    return {
      "w-full": this.isFullWidth,
      blocked: this.isBlocked,
      "with-bg": this.colorStyle === "background",
      "without-bg": this.colorStyle === "outline",
      "with-right-margin": this.withRightMargin,
      "is-label-centered": this.isLabelCentered,
      "is-not-clickable": !this.isRemovable,
      "is-disabled-with-opacity": this.isDisabledWithOpacity,
    };
  }
}
