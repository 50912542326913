<ng-template #officeSwitcherTemplate>
  <div id="office-switcher-page-frame-container">
    @if (!(noOfficeId$ | async) && !(isClientAppEnv$ | async)) {
      <div id="office-switch-container" class="mr-2xl">
        <db-office-switcher
          [officeChangeGtmEventName]="officeChangeGtmEventName"
        ></db-office-switcher>
      </div>
    }

    <div id="other">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
  </div>
</ng-template>
<db-page-frame
  [rightSideHeaderTemplate]="false"
  [title]="title"
  [headerTemplate]="officeSwitcherTemplate"
  [showProgressLoader]="showProgressLoader"
>
  <ng-content></ng-content>
</db-page-frame>
