import {
  Directive,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { HoverContainerDirective } from "./hover-container.directive";
import { IHoverItem } from "./hover.interface";

@Directive({
  selector: "[dbHover]",
  standalone: true,
})
export class HoverDirective
  implements OnInit, OnChanges, OnDestroy, IHoverItem
{
  @HostBinding("class.hovered") @Input() isHovered = false;
  private readonly hoverContainer = inject(HoverContainerDirective, {
    optional: true,
  });

  elementRef = inject(ElementRef<HTMLElement>);

  ngOnInit(): void {
    this.hoverContainer?.registerHoverItem(this);
  }

  ngOnChanges(): void {
    this.hoverContainer?.registerHoverItem(this);
  }

  ngOnDestroy(): void {
    this.hoverContainer?.unregisterHoverItem(this);
  }
}
