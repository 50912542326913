import { Injectable } from "@angular/core";
import { BehaviorSubject, map, take } from "rxjs";
import { DialogContainerComponent } from "./dialog-container.component";

@Injectable({
  providedIn: "root",
})
export class DialogContainerService {
  private openDialogs$$ = new BehaviorSubject<DialogContainerComponent[]>([]);
  public openDialogs$ = this.openDialogs$$.asObservable();

  addDialog(dialog: DialogContainerComponent): void {
    this.openDialogs$
      .pipe(
        take(1),
        map((dialogs) => [...dialogs, dialog]),
      )
      .subscribe((d) => this.openDialogs$$.next(d));
  }

  removeDialog(dialog: DialogContainerComponent): void {
    this.openDialogs$
      .pipe(
        take(1),
        map((dialogs) => dialogs.filter((d) => d !== dialog)),
      )
      .subscribe((d) => this.openDialogs$$.next(d));
  }
}
