import { Component, EventEmitter, input, Input, Output } from "@angular/core";
import { ButtonComponent } from "../button";

@Component({
  selector: "db-empty-state",
  templateUrl: "./empty-state.component.html",
  styleUrls: ["./empty-state.component.scss"],
  standalone: true,
  imports: [ButtonComponent],
})
export class EmptyStateComponent {
  @Input() imageSrc = "";
  @Input() title = "";
  @Input() body = "";
  imageHeight = input<number>(60);

  @Input() isWhite = false;

  @Input() action = "";
  actionIcon = input<string>("");
  @Output() actionClick = new EventEmitter<void>();
}
