import { HttpErrorResponse } from "@angular/common/http";
import { props } from "@ngrx/store";
import { ICompany, IOffice } from "common-module";
import { forNamespace } from "ngrx-action-bundles";
import {
  IUserCsvProcessState,
  IUserCsvUploadFirestoreEntry,
  FeatureAccess,
} from "types";
import { IInvoiceBanner } from "types/interfaces/invoice-banner";

const factory = forNamespace("GLOBAL MODULE");

export const setAdminAppCompanyIdBundle = factory.singleAction(
  "setAdminAppCompanyId",
  props<{ companyId: string | null }>(),
);

export const addOfficeBundle = factory.singleAction(
  "addOffice",
  props<{ office: IOffice }>(),
);

export const updateOfficeBundle = factory.singleAction(
  "updateOffice",
  props<{ updates: { id: string } & Partial<IOffice> }>(),
);

export const removeOfficeBundle = factory.singleAction(
  "removeOffice",
  props<{ officeId: string }>(),
);

export const setClientAppCompanyIdBundle = factory.singleAction(
  "setClientAppCompanyId",
  props<{ companyId: string | null }>(),
);

export const setClientAppOfficeIdBundle = factory.singleAction(
  "setClientAppOfficeId",
  props<{ officeId: string | null }>(),
);

export const setAdminAppOfficeIdBundle = factory.singleAction(
  "setAdminAppOfficeId",
  props<{ officeId: string | null }>(),
);

export const setNoCompanyBundle = factory.singleActionWithCleanup(
  "noCompany",
  props<{ hasNoCompany: boolean }>(),
);

export const setIsDeskbirdAdminBundle = factory.singleAction(
  "setIsDeskbirdAdmin",
  props<{ isDeskbirdAdmin: boolean }>(),
);

export const setNoOfficePrimaryBundle = factory.singleActionWithCleanup(
  "setNoPrimaryOffice",
  props<{ hasNoPrimaryOffice: boolean }>(),
);

export const loadCompanyBundle = factory.asyncActionWithCleanup(
  "loadCompany",
  props<{ companyId: string }>(),
  props<{ company: ICompany }>(),
  props<{ error: any; companyId: string }>(),
  props<{ companyId: string }>(),
);

export const setCompanyBundle = factory.singleAction(
  "setCompany",
  props<{ company: ICompany }>(),
);

export const updateCompanyBundle = factory.asyncAction(
  "updateCompany",
  props<{ company: ICompany }>(),
  undefined,
  props<{ error: HttpErrorResponse }>(),
);

export const setCompanyInStoreBundle = factory.singleAction(
  "setCompanyInStore",
  props<{ company: ICompany }>(),
);

export const addCompanyBundle = factory.singleAction(
  "addCompany",
  props<{ company: ICompany }>(),
);

export const loadCompaniesBundle = factory.asyncActionWithCleanup(
  "loadCompanies",
  undefined,
  props<{ companies: ICompany[] }>(),
  props<{ error: any }>(),
);

export const loadOfficeBundle = factory.asyncActionWithCleanup(
  "loadOffice",
  props<{ officeId: string }>(),
  props<{ office: IOffice }>(),
  props<{ error: any; officeId: string }>(),
  props<{ officeId: string }>(),
);

export const loadOfficesBundle = factory.asyncActionWithCleanup(
  "loadOffices",
  props<{ companyId: string; selectedOfficeId?: string }>(),
  props<{ offices: IOffice[]; selectedOfficeId?: string; timestamp: number }>(),
  props<{ error: any; timestamp: number }>(),
  undefined,
  undefined,
  true,
);

export const setAdminAppOfficeBundle = factory.singleAction(
  "setAdminAppOffice",
  props<{ office: IOffice }>(),
);
export const setClientAppOfficeBundle = factory.singleAction(
  "setClientAppOffice",
  props<{ office: IOffice }>(),
);

export const recheckUserCsvImportStatusBundle = factory.singleAction(
  "recheckUserCsvImportStatus",
);
export const checkUserCsvImportStatusBundle = factory.asyncActionWithCleanup(
  "checkUserCsvImportStatus",
  props<IUserCsvUploadFirestoreEntry>(),
  props<{
    processState: IUserCsvProcessState | null;
    importCsvId: number | null;
    fileSize: number | null;
    fileName: string | null;
    uploadDate: string | null;
  }>(),
  props<{ error: Error }>(),
  undefined,
  undefined,
  true,
);

export const setUserCsvImportStatusBundle = factory.singleAction(
  "setUserCsvImportStatus",
  props<{
    processState: IUserCsvProcessState | null;
    importCsvId: number | null;
    fileSize: number | null;
    fileName: string | null;
    uploadDate: string | null;
  }>(),
);

export const loadAppInfoBundle = factory.asyncAction(
  "loadAppInfo",
  props<{ failureCount?: number }>(),
  undefined,
  props<{ error: any }>(),
);
export const reloadApplicationBundle =
  factory.singleAction("reloadApplication");
export const setIdleAppValueBundle = factory.singleAction(
  "setIdleAppValue",
  props<{ isAppIdle: boolean }>(),
);

export const updateAllowsUsersToManageOfficeRolesBundle = factory.asyncAction(
  "updateAllowsUsersToManageOfficeRoles",
  props<{ allowsUsersToManageOfficeRoles: boolean; companyId: string }>(),
  props<{ allowsUsersToManageOfficeRoles: boolean; companyId: string }>(),
);

export const loadInvoiceBannerBundle = factory.asyncAction(
  "loadInvoiceBanner",
  props<{ companyId: string }>(),
  props<{ invoiceBanner: IInvoiceBanner }>(),
  props<{ error: HttpErrorResponse }>(),
);

export const loadCompanyFeatureAccess = factory.asyncAction(
  "loadCompanyFeatureAccess",
  props<{ companyUuid: string }>(),
  props<FeatureAccess>(),
  props<{ error: HttpErrorResponse }>(),
);

export const globalBundles = [
  setAdminAppCompanyIdBundle,
  setClientAppCompanyIdBundle,

  setClientAppOfficeIdBundle,
  setAdminAppOfficeIdBundle,

  setNoCompanyBundle,
  setNoOfficePrimaryBundle,

  loadCompanyBundle,
  loadCompaniesBundle,
  setCompanyBundle,
  addCompanyBundle,
  loadOfficeBundle,
  loadOfficesBundle,
  updateCompanyBundle,
  setCompanyInStoreBundle,

  setAdminAppOfficeBundle,
  setClientAppOfficeBundle,

  loadAppInfoBundle,
  reloadApplicationBundle,

  addOfficeBundle,
  removeOfficeBundle,
  setIsDeskbirdAdminBundle,
  updateOfficeBundle,

  setUserCsvImportStatusBundle,
  checkUserCsvImportStatusBundle,
  recheckUserCsvImportStatusBundle,
  setIdleAppValueBundle,
  updateAllowsUsersToManageOfficeRolesBundle,
  loadInvoiceBannerBundle,
  loadCompanyFeatureAccess,
];
