import { IDeskArea } from "./desk-area";

export interface IExtendedDeskArea extends IDeskArea {
  isRemoved?: boolean;
  isNew?: boolean;
}

export interface ICreateDeskAreaDto extends IExtendedDeskArea {
  quantity: { total: number };
}
