import { Component, inject } from "@angular/core";
import { AbstractControl, NgForm } from "@angular/forms";
import { interval, race } from "rxjs";
import { map, startWith, take } from "rxjs/operators";
import { Validators } from "@angular/forms";
import { RouterModel } from "router-module";
import { Lottie } from "lottie-module";
import { NotificationModel } from "notification-module";
import { TeamsService } from "../services/teams.service";
import { LoadingTenseState } from "db-ui";
import { AuthModel } from "../+store/model";

@Component({
  selector: "db-check-in",
  templateUrl: "./check-in.component.html",
  styleUrls: ["./check-in.component.scss"],
})
export class CheckInComponent {
  routerModel = inject(RouterModel);
  authModel = inject(AuthModel);
  notificationModel = inject(NotificationModel);
  teamsService = inject(TeamsService);

  showLottie$ = interval(0).pipe(
    take(1),
    map(() => true),
    startWith(false),
  );

  isLoading = false;
  LoadingTenseState = LoadingTenseState;
  Lottie = Lottie;

  formValue = {
    email: "",
    accept: false,
  };

  constructor() {
    this.routerModel.selectors.queryParams$.pipe(take(1)).subscribe((qp) => {
      const email = qp?.["email"] as string | undefined;
      if (!email) return;
      this.formValue = { email, accept: true };
      this.isLoading = true;
      this.checkIn(email);
    });

    this.teamsService
      .getContext()
      .then((context) => {
        const email = context.user?.userPrincipalName;
        if (
          email &&
          Validators.email({ value: email } as AbstractControl) === null
        ) {
          this.formValue.email = email || "";
        }
      })
      .catch((err) => console.error(err));
  }

  submitForm(form: NgForm): void {
    if (form.invalid || this.isLoading) return;
    const emailControl = form.controls["email"] as AbstractControl<
      string,
      string
    >;
    this.checkIn(emailControl.value);
  }

  checkIn(email: string): void {
    this.isLoading = true;
    this.authModel.actions.dispatch.checkEmail({ email });
    race(
      this.authModel.actions.listen.checkEmailSuccess$,
      this.authModel.actions.listen.checkEmailFailure$,
    )
      .pipe(take(1))
      .subscribe((result) => {
        this.isLoading = false;
        if ("error" in result) return;
        const {
          emailCheckResult: { companyExists, inviteOnlyAccess, userInvited },
        } = result;

        if (!companyExists) {
          this.routerModel.actions.dispatch.navigate({
            commands: ["login", "not-active-company"],
          });
          return;
        }

        if (inviteOnlyAccess && !userInvited) {
          this.routerModel.actions.dispatch.navigate({
            commands: ["login", "not-active-user"],
          });
          return;
        }

        this.routerModel.actions.dispatch.navigate({
          commands: ["login", "sign-in"],
          extras: { queryParamsHandling: "preserve" },
        });
      });
  }
}
