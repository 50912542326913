import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UserGroupAvatarSize } from "shared-types";

@Component({
  selector: "db-user-group-avatar",
  standalone: true,
  templateUrl: "./user-group-avatar.component.html",
  styleUrl: "./user-group-avatar.component.scss",
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupAvatarComponent {
  @Input() avatarSize: UserGroupAvatarSize = "standard"; // NOTE: when adding new sizes, please update properly the scss
}
