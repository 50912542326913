<db-multiselect
  [ngClass]="class"
  [options]="$any(group ? groupedOptions : singleItemOptions)"
  [optionLabel]="'label'"
  [optionValue]="'value'"
  [chip]="true"
  [group]="group"
  [showToggleAll]="showToggleAll"
  [showClear]="showClear"
  [filter]="true"
  [filterBy]="'label,userEntity.email'"
  [panelStyleClass]="panelStyleClass"
  [placeholder]="placeholder"
  [dataTestId]="dataTestId"
  [resetFilterOnHide]="true"
  [itemTemplate]="itemTemplate"
  [groupTemplate]="groupTemplate"
  [virtualScroll]="virtualScroll"
  (onChange)="selectedValuesHandler($event.value)"
  (onClear)="clearSelectionHandler()"
/>

<ng-template #itemTemplate let-item>
  <div>
    @switch (item.type) {
      @case (UserMultiselectType.USER) {
        <db-user-info
          [userInfo]="item.userEntity"
          [showUserEmail]="true"
          avatarSize="mini"
          [ignoreEmailMaxWidth]="false"
        >
        </db-user-info>
      }
      @default {
        <div>
          {{ item.label }}
        </div>
      }
    }
  </div>
</ng-template>

<ng-template #groupTemplate let-group>
  <div class="flex items-center">
    <span>{{ group.label }}</span>
  </div>
</ng-template>
