import {
  IBookingViewProps,
  IDayOpeningHours,
  IUserInfo,
  IUserSpaceView,
  IUserView,
} from "types";
import { IOfficeEvent } from "./office-events";

export interface IDayViewMySelectedOption {
  /** Id of selected status option */
  optionId: string;
  schedulingEntryId: string;
  officeId: string;
  officeName: string;
  /** Flag for when the user can not remove the already selected option, because status is created through external HRIS provider */
  canBeRemoved: boolean;
  hrisLogo?: string;
  validationStatus?: string | null;
  externalCalendarEventId?: string | null;
  externalStatus?: boolean;
  externalStatusId?: string | null;

  /** Resource booking related properties */
  bookingIds?: string[];
  bookings?: IBookingViewProps[];
  guestBookings?: IBookingViewProps[];
  userSpacesInfo?: IUserSpaceView[];
}

export interface IDayView {
  // Day in format YYYY-MM-DD
  readonly day: string;
  readonly totalOthersCount: number;
  readonly guestsCount: number;
  readonly mySelectedOption?: IDayViewMySelectedOption;
  readonly favUserData: IUserView[];
  readonly officeEvents?: IOfficeEvent[];

  readonly officeId: string;
  readonly officeName: string;
  readonly isOfficeClosed: boolean;
  readonly timeZone?: string;
  readonly operatingHours: IDayOpeningHours | null;
  readonly lastUpdatedBy?: IUserInfo & { updatedAt: string };

  // props set by UI only
  isDummyValue?: boolean;
  isRemovingStatus: boolean;
  isCreatingStatus: boolean;
  showWeeksDivider?: boolean;
  showFollowColleaguesPrompt?: boolean;
  showBookASpaceButton?: boolean;
  isOutsideOfficeLeadTime?: boolean;
  showUserSpaces?: boolean;
  showBookings?: boolean;
  height?: number;
}
