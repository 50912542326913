import { inject, Injectable, LOCALE_ID } from "@angular/core";
import { AvailableLocales } from "../enums/available-locales.enum";

@Injectable({
  providedIn: "root",
})
export class LocaleUrlService {
  private readonly localeId = inject(LOCALE_ID);

  getLocalizedUrl(path: string = "/product-upgrade"): string {
    const currentLocale = this.localeId.split("-")[0] as AvailableLocales;
    const baseUrl = "https://deskbird.com";

    switch (currentLocale) {
      case AvailableLocales.German:
        return `https://de.deskbird.com${path}`;
      case AvailableLocales.Spanish:
        return `https://es.deskbird.com${path}`;
      case AvailableLocales.French:
        return `https://fr.deskbird.com${path}`;
      case AvailableLocales.Italian:
        return `https://it.deskbird.com${path}`;
      case AvailableLocales.Dutch:
        return `https://nl.deskbird.com${path}`;
      default:
        return `${baseUrl}${path}`;
    }
  }
}
