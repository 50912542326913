@switch (skeletonType) {
  @case (SkeletonLoaderType.NONE) {}
  @case (SkeletonLoaderType.DEFAULT) {
    <p-skeleton
      [styleClass]="skeletonClass"
      [borderRadius]="borderRadius"
      [width]="skeletonWidth"
      [height]="skeletonHeight"
    ></p-skeleton>
  }
  @case (SkeletonLoaderType.DEFAULT_TWO_LINES) {
    <div class="skeleton-group-column">
      <p-skeleton
        [borderRadius]="borderRadius"
        width="10rem"
        styleClass="mb-sm"
      ></p-skeleton>
      <p-skeleton [borderRadius]="borderRadius" width="10rem"></p-skeleton>
    </div>
  }
  @case (SkeletonLoaderType.CIRCLE) {
    <p-skeleton shape="circle" [size]="skeletonHeight"></p-skeleton>
  }
  @case (SkeletonLoaderType.TWO_CIRCLES) {
    <div class="flex">
      <p-skeleton
        [style]="{ marginRight: '27px' }"
        shape="circle"
        [size]="skeletonHeight"
      ></p-skeleton>
      <p-skeleton shape="circle" [size]="skeletonHeight"></p-skeleton>
    </div>
  }
  @case (SkeletonLoaderType.CIRCLE_AND_DEFAULT) {
    <div class="skeleton-group-row">
      <p-skeleton shape="circle" size="2rem" styleClass="mr-lg"></p-skeleton>
      <div class="flex-1">
        <p-skeleton [borderRadius]="borderRadius"></p-skeleton>
      </div>
    </div>
  }
  @case (SkeletonLoaderType.CIRCLE_AND_DEFAULT_TWO_LINES) {
    <div class="skeleton-group-row">
      <p-skeleton shape="circle" size="2rem" styleClass="mr-lg"></p-skeleton>
      <div class="skeleton-group-column">
        <p-skeleton
          [borderRadius]="borderRadius"
          width="10rem"
          styleClass="mb-sm"
        ></p-skeleton>
        <p-skeleton [borderRadius]="borderRadius" width="10rem"></p-skeleton>
      </div>
    </div>
  }
  @default {
    <p-skeleton></p-skeleton>
  }
}
