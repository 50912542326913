import {
  ChangeDetectionStrategy,
  Component,
  input,
  Input,
} from "@angular/core";
import { Tag, TagModule } from "primeng/tag";
import { TooltipModule } from "primeng/tooltip";
import { NgClass } from "@angular/common";

@Component({
  selector: "db-tag",
  standalone: true,
  imports: [TagModule, TooltipModule, NgClass],
  styleUrls: ["./tag.component.scss"],
  template: `
    <p-tag
      [value]="text"
      [pTooltip]="tooltipText"
      [tooltipPosition]="'top'"
      [styleClass]="backgroundColor() + ' ' + styleClass()"
      class="flex"
      [rounded]="rounded()"
      [severity]="severity()"
    ></p-tag>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input({ required: true }) text!: string;
  @Input() tooltipText?: string;
  backgroundColor = input<string>("");
  styleClass = input<string>("");
  rounded = input<boolean>(false);
  severity = input<Tag["severity"]>(undefined);
}
