/* eslint-disable @typescript-eslint/no-unused-vars */
import { IUserFilterService } from "../services/user";
import { Observable, of } from "rxjs";
import { IUserGroupSearchFilter, IUserGroupSearchResult } from "types";
import { PaginatedResult } from "types/interfaces/paginated-response";
import { usersMock } from "./users-mock";

interface FilterParams {
  searchQuery?: string;
  excludeUserIds?: string[];
}

export class MockUserService implements IUserFilterService {
  loadUsersForCompanyFiltered(params: FilterParams) {
    const { searchQuery = "", excludeUserIds = [] } = params;
    const query = searchQuery.toLowerCase();

    const filteredUsers = usersMock.filter((user) => {
      if (!user.firstName || !user.lastName || !user.email || !user.id)
        return false;

      const matchesQuery =
        user.firstName.toLowerCase().includes(query) ||
        user.lastName.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query);
      const isExcluded = excludeUserIds.includes(user.id);
      return matchesQuery && !isExcluded;
    });

    return of({
      data: filteredUsers,
      offset: 0,
      limit: filteredUsers.length,
      total: filteredUsers.length,
    });
  }

  loadGroupsForCompanyFiltered({
    companyId,
    searchQuery,
    groupIds,
    excludeGroupIds,
    include,
    offset,
    limit,
    userStatuses,
  }: IUserGroupSearchFilter): Observable<
    PaginatedResult<IUserGroupSearchResult>
  > {
    throw new Error("Method not implemented.");
  }
}
