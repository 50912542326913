import { Injectable } from "@angular/core";
import { UserRole } from "types/enums/user-role";
import { FeatureAccess } from "types/interfaces/feature-access";
import { FeatureAccessType, IUser } from "types";
import { ICorporateInfo } from "common-module";
import { hasAccessToPageFactory } from "shared";
import {
  OFFICE_EVENTS_ROUTE,
  OFFICE_ADMIN_ROUTE,
  PEOPLE_ANALYTICS_ROUTE,
  USER_ADMIN_ROUTE,
} from "auth-module/constants";

@Injectable({
  providedIn: "root",
})
export class FeatureAccessRoutingService {
  /**
   * Retrieves the initial route for administration based on the user's role and feature access.
   *
   * @param authUser - The authenticated user object.
   * @param mppsEnabled - A boolean indicating if MPPS is enabled.
   * @param isDbAdmin - A boolean indicating if the user is a deskbird administrator.
   * @param featureAccess - The feature access containing the user's feature permissions.
   * @param companyBasePath - The base path for the company (e.g. /admin/company/375).
   * @param companyBasePath - The base path for the company (e.g. /admin/company/375).
   * @param userRolePageRestrictions - The company restrictions for roles and pages.
   * @returns A string representing the initial route or null if no route is determined.
   */
  getInitialRouteForAdministration(
    authUser: IUser,
    mppsEnabled: boolean,
    isDbAdmin: boolean,
    featureAccess: FeatureAccess | null,
    companyBasePath: string | null = null,
    userRolePageRestrictions: string[] | null = null,
  ): string | null {
    const hasAccessToPage = hasAccessToPageFactory(userRolePageRestrictions);

    const hasAccessToOfficeAdministration =
      [UserRole.ADMIN, UserRole.OFFICE_ADMIN].includes(authUser.role) &&
      hasAccessToPage(OFFICE_ADMIN_ROUTE);
    const hasAccessToUserAdministration =
      hasAccessToOfficeAdministration && hasAccessToPage(USER_ADMIN_ROUTE);
    const hasAccessToPeopleAdministration =
      (hasAccessToOfficeAdministration ||
        [UserRole.MANAGER, UserRole.GROUP_MANAGER].includes(authUser.role)) &&
      hasAccessToPage(PEOPLE_ANALYTICS_ROUTE);
    const hasAccessToOfficeEventAdministration = [
      UserRole.MANAGER,
      UserRole.GROUP_MANAGER,
    ].includes(authUser.role);

    const route = this.getFirstAccessibleAdminRoute(
      hasAccessToOfficeAdministration || isDbAdmin,
      hasAccessToUserAdministration || isDbAdmin,
      hasAccessToPeopleAdministration || isDbAdmin,
      hasAccessToOfficeEventAdministration || isDbAdmin,
      mppsEnabled,
      featureAccess,
    );

    if (route !== null) {
      return companyBasePath ? `${companyBasePath}/${route}` : route;
    }

    return null;
  }

  /**
   * Retrieves the initial route for a client based on feature access and corporate information.
   *
   * @param mppsEnabled - A boolean indicating if MPPS is enabled.
   * @param corporateInfo - The company information.
   * @param featureAccess - The feature access containing the user's feature permissions.
   * @returns A string representing the initial route.
   */
  getInitialRouteForClient(
    mppsEnabled: boolean,
    corporateInfo: ICorporateInfo,
    featureAccess: FeatureAccess | null,
  ): string {
    if (!mppsEnabled || !featureAccess) {
      return corporateInfo?.allowsScheduling
        ? "/planning"
        : corporateInfo?.allowsResourceBooking
          ? "/office"
          : "/no-access";
    }

    return this.hasSomeFeatureAccess(
      FeatureAccessType.SCHEDULING,
      featureAccess,
    )
      ? "/planning"
      : "/office";
  }

  private hasSomeFeatureAccess(
    feature: FeatureAccessType | FeatureAccessType[],
    featureAccess: FeatureAccess,
  ): boolean {
    const features = Array.isArray(feature) ? feature : [feature];
    return features.some((f) => featureAccess.features.includes(f));
  }

  private getFirstAccessibleAdminRoute(
    hasAccessToOfficeAdministration: boolean,
    hasAccessToUserAdministration: boolean,
    hasAccessToPeopleAdministration: boolean,
    hasAccessToOfficeEventAdministration: boolean,
    mppsEnabled: boolean,
    featureAccess: FeatureAccess | null,
  ): string | null {
    if (!mppsEnabled || !featureAccess) {
      return hasAccessToOfficeAdministration
        ? OFFICE_ADMIN_ROUTE
        : hasAccessToPeopleAdministration
          ? PEOPLE_ANALYTICS_ROUTE
          : null;
    }

    if (
      hasAccessToOfficeAdministration &&
      this.hasSomeFeatureAccess(
        [FeatureAccessType.RESOURCE_BOOKING, FeatureAccessType.ROOMS],
        featureAccess,
      )
    ) {
      return OFFICE_ADMIN_ROUTE;
    }

    if (
      hasAccessToUserAdministration &&
      this.hasSomeFeatureAccess(
        [
          FeatureAccessType.WORKFORCE_ANALYTICS,
          FeatureAccessType.OFFICE_EVENTS,
          FeatureAccessType.SCHEDULING,
          FeatureAccessType.SCHEDULING_CALENDAR_SYNC,
          FeatureAccessType.HYBRID_WORK_POLICIES,
        ],
        featureAccess,
      )
    ) {
      return USER_ADMIN_ROUTE;
    }

    if (
      hasAccessToPeopleAdministration &&
      this.hasSomeFeatureAccess(
        FeatureAccessType.WORKFORCE_ANALYTICS,
        featureAccess,
      )
    ) {
      return PEOPLE_ANALYTICS_ROUTE;
    }

    // every non-user role should have access to it (if feature is accessible)
    if (
      hasAccessToOfficeEventAdministration &&
      this.hasSomeFeatureAccess(FeatureAccessType.OFFICE_EVENTS, featureAccess)
    ) {
      return OFFICE_EVENTS_ROUTE;
    }

    return null;
  }
}
