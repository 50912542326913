import { IUserInfo } from "types/interfaces/user-info";
import { IUserGroupSearchResult } from "types/interfaces/users/user-group-search-result";

/**
 * FormSelection*Item are used for reactive forms integration.
 * It contains only the minimal information needed to identify a selection.
 */
export type FormSelectionUserItem = {
  type: "user";
  id: string;
};
export type FormSelectionGroupItem = {
  type: "group";
  id: string;
};
export type FormSelectionExternalEmailItem = {
  type: "externalEmail";
  email: string;
};
export type FormSelectionItem =
  | FormSelectionUserItem
  | FormSelectionGroupItem
  | FormSelectionExternalEmailItem;

/**
 * SelectionItem is used for direct component usage with initiallySelected* inputs and selectionChange output.
 * It contains the full information about the selected item.
 */
export type SelectionUserItem = {
  type: "user";
  key: string;
  user: IUserInfo;
};
export type SelectionGroupItem = {
  type: "group";
  key: string;
  group: IUserGroupSearchResult;
};
export type SelectionExternalEmailItem = {
  type: "externalEmail";
  key: string;
  email: string;
};
export type SelectionItem =
  | SelectionUserItem
  | SelectionGroupItem
  | SelectionExternalEmailItem;
