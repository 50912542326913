import { registerLocaleData } from "@angular/common";
import { LOCALE_ID, StaticProvider, Type } from "@angular/core";
import { loadTranslations } from "@angular/localize";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AvailableLocales, LOCALE } from "shared";
import { loadFontsWithWebFont } from "common-module";
import {
  LOCALE_ID_NO_REGION,
  PRIME_NG_TRANSLATION,
  dbToLanguageAndRegionLocale,
} from "./constants";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";

import localeEn from "@angular/common/locales/en";
import localeDe from "@angular/common/locales/de";
import localeFr from "@angular/common/locales/fr";
import localeIt from "@angular/common/locales/it";
import localeEs from "@angular/common/locales/es";
import localeUk from "@angular/common/locales/uk";
import localeNl from "@angular/common/locales/nl";
import localeHu from "@angular/common/locales/hu";
import localeCs from "@angular/common/locales/cs";

import localeEnExtra from "@angular/common/locales/extra/en";
import localeDeExtra from "@angular/common/locales/extra/de";
import localeFrExtra from "@angular/common/locales/extra/fr";
import localeItExtra from "@angular/common/locales/extra/it";
import localeEsExtra from "@angular/common/locales/extra/es";
import localeUkExtra from "@angular/common/locales/extra/uk";
import localeNlExtra from "@angular/common/locales/extra/nl";
import localeHuExtra from "@angular/common/locales/extra/hu";
import localeCsExtra from "@angular/common/locales/extra/cs";

import moment from "moment";
import {
  PrimeNgTranslation,
  getPrimeNgTranslation,
} from "./prime-ng-translation-utils";

registerLocaleData(localeEn, "en-GB", localeEnExtra);
registerLocaleData(localeDe, "de-DE", localeDeExtra);
registerLocaleData(localeFr, "fr-FR", localeFrExtra);
registerLocaleData(localeIt, "it-IT", localeItExtra);
registerLocaleData(localeEs, "es-ES", localeEsExtra);
registerLocaleData(localeUk, "uk-UA", localeUkExtra);
registerLocaleData(localeNl, "nl-NL", localeNlExtra);
registerLocaleData(localeHu, "hu-HU", localeHuExtra);
registerLocaleData(localeCs, "cs-CZ", localeCsExtra);

const availableLocales = Object.values(AvailableLocales).filter(
  (value) => typeof value === "string",
) as string[];

function getLocale(localeString: string | null): AvailableLocales {
  return availableLocales.includes(localeString || "[NONE]")
    ? (localeString as AvailableLocales)
    : AvailableLocales.English;
}

function fetchLocale(locale: AvailableLocales) {
  const path = `/assets/locales/messages.${locale}.json`;

  return fetch(path)
    .then((res) => res.json())
    .catch((err) => console.error(err));
}

export function bootstrapWithLanguage(
  appModule: Type<any>,
  forceLocale: string | null = null,
  extraProviders: StaticProvider[] = [],
) {
  const validForceLocale =
    forceLocale !== null && availableLocales.includes(forceLocale);
  loadFontsWithWebFont();
  const localeCurrent = localStorage.getItem(LOCALE) || null;
  const localeValue = validForceLocale ? forceLocale : localeCurrent;
  const locale = getLocale(localeValue) as AvailableLocales;
  let primeNgTranslation: PrimeNgTranslation | null = null;
  moment.locale(locale);
  if (localeValue !== localeCurrent) {
    localStorage.setItem(LOCALE, locale);
  }

  Promise.all([fetchLocale(locale), getPrimeNgTranslation(locale)])
    .then(([translations, primeNgTranslationLocaleByLocale]) => {
      primeNgTranslation = primeNgTranslationLocaleByLocale;
      if (translations) {
        loadTranslations(translations);
      }
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      const localeWithRegion = dbToLanguageAndRegionLocale[locale];

      return platformBrowserDynamic([
        { provide: LOCALE_ID, useValue: localeWithRegion },
        { provide: MAT_DATE_LOCALE, useValue: localeWithRegion },
        { provide: LOCALE_ID_NO_REGION, useValue: locale },
        { provide: PRIME_NG_TRANSLATION, useValue: primeNgTranslation },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        ...extraProviders,
      ]).bootstrapModule(appModule);
    });
}
