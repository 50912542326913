import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectButtonModule } from "primeng/selectbutton";
import { CardSelectorItem } from "./card-selector.types";
import { NgClass, NgStyle } from "@angular/common";
import { ChipComponent } from "../chip";
import { ButtonComponent } from "../button";

@Component({
  selector: "db-card-selector",
  standalone: true,
  imports: [
    SelectButtonModule,
    NgClass,
    NgStyle,
    ChipComponent,
    ButtonComponent,
  ],
  templateUrl: "./card-selector.component.html",
  styleUrls: ["./card-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CardSelectorComponent,
      multi: true,
    },
  ],
})
export class CardSelectorComponent implements OnChanges, ControlValueAccessor {
  @Input({ required: true }) items!: CardSelectorItem[];
  @Input() disabled?: boolean;

  @Output() clickedOnDisabledItem = new EventEmitter<{
    cardItem: CardSelectorItem;
    event: MouseEvent;
  }>();

  value: CardSelectorItem | null = null;
  touched = false;
  isDisabled = false;

  onChange = (cardName: string) => {};
  onTouched = () => {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes["disabled"] && changes["disabled"].currentValue !== undefined) {
      this.isDisabled = changes["disabled"].currentValue;
    }
  }

  onSelectedItem(item: CardSelectorItem, $event: MouseEvent) {
    this.markAsTouched();
    if (item.disabled) {
      this.clickedOnDisabledItem.emit({ cardItem: item, event: $event });
      return;
    }
    if (!this.isDisabled) {
      this.value = item;
      this.onChange(this.value.id);
    }
  }

  writeValue(cardName: string) {
    const selection = this.items.find((item) => item.id === cardName);
    this.value = selection ?? null;
  }

  registerOnChange(onChange: (cardName: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    if (!this.disabled) {
      this.isDisabled = disabled;
    }
  }
}
