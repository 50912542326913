import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICompany, IOffice, ListResponse } from "common-module";
import { Observable } from "rxjs";
import { UserCSVStatusResponse } from "types";
import { IInvoiceBannerResponse } from "types/interfaces/invoice-banner";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(private http: HttpClient) {}

  loadCompany(id: string): Observable<ICompany> {
    return this.http.get<ICompany>(`/api/businesscompany/${id}`);
  }

  updateCompany(id: string, company: Partial<ICompany>): Observable<void> {
    return this.http.put<void>(`/api/businesscompany/${id}`, { ...company });
  }

  loadOffice(id: string): Observable<IOffice> {
    return this.http.get<IOffice>(`/api/internalWorkspaces/${id}`);
  }

  loadOffices(companyId: string): Observable<ListResponse<IOffice>> {
    const search = new URLSearchParams();
    search.set("companyId", companyId);
    search.set("includeInactive", "true");
    return this.http.get<ListResponse<IOffice>>(
      `/api/company/internalWorkspaces?${search.toString()}`,
    );
  }

  loadCompanies(): Observable<ListResponse<ICompany>> {
    return this.http.get<ListResponse<ICompany>>("/api/businesscompany");
  }

  loadAppInfo(): Observable<string> {
    const headers = new HttpHeaders({
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post-check=0, pre- check=0",
      Pragma: "no-cache",
      Expires: "0",
    });

    return this.http.get<string>("/assets/app-info.json", {
      headers,
      responseType: "json",
    });
  }

  checkUploadStatus(importCSVId: number) {
    return this.http.get<UserCSVStatusResponse>(
      `/api/users/importUserCSVStatus?importCSVId=${importCSVId}`,
    );
  }

  updateAllowsUsersToManageOfficeRoles(
    companyId: string,
    allowsUsersToManageOfficeRoles: boolean,
  ) {
    return this.http.patch(`/api/company/${companyId}/officeRoles/settings`, {
      allowsUsersToManageOfficeRoles,
    });
  }

  loadInvoiceBanner(companyId: string): Observable<IInvoiceBannerResponse> {
    return this.http.get(
      `/api/invoices/company/${companyId}/openInvoices`,
    ) as Observable<IInvoiceBannerResponse>;
  }

  loadCompanyFeatureAccess(companyUuid: string): Observable<{
    features: string[];
    quotas: { name: string; quota: number }[];
  }> {
    return this.http.get("/api/featureAccess", {
      params: { companyId: companyUuid },
    }) as Observable<{
      features: string[];
      quotas: { name: string; quota: number }[];
    }>;
  }
}
