@if (!!label) {
  <label class="d-block small-text-bold mb-4">
    {{ label }}
    @if (showAsterisk) {
      *
    }
    @if (optional) {
      <i i18n="@@shared|optional-label">(optional)</i>
    }
  </label>
}
<div class="db-autocomplete-users-single-select">
  <p-autoComplete
    [delay]="0"
    [ngModel]="currentSelection"
    [dataKey]="'key'"
    [optionLabel]="'key'"
    [suggestions]="suggestions"
    (completeMethod)="onSearch($event)"
    [multiple]="true"
    [placeholder]="placeholder"
    [size]="'small'"
    [forceSelection]="true"
    [autoOptionFocus]="true"
    [showClear]="showClear"
    [emptyMessage]="emptyMessage"
    [minLength]="1"
    [scrollHeight]="'320px'"
    [lazy]="true"
    (onSelect)="onSelect($event)"
    (onUnselect)="onUnselect($event)"
    (onClear)="onClear()"
    (onBlur)="onBlur()"
    [attr.data-testid]="dataTestId"
    [inputStyleClass]="'db-autocomplete-users-input'"
    [styleClass]="'w-100'"
  >
    <ng-template let-suggestion let-i="index" pTemplate="item">
      <div
        class="d-flex align-items-center"
        [ngStyle]="{ 'max-width': '462px' }"
      >
        @switch (suggestion.type) {
          @case ("user") {
            <db-user-info
              [style]="{ width: '100%' }"
              [userInfo]="suggestion.user"
              [showUserEmail]="true"
              [fullWidth]="true"
              containerPadding="10px 16px"
              containerDataTestId="autocomplete--search-result"
              avatarDataTestId="autocomplete--search-result-avatar"
              [showEmailIfNameNotAvailable]="true"
              [ignoreEmailMaxWidth]="true"
              [avatarSize]="'mini'"
              [attr.data-testid]="
                dataTestId + '--search-result-user-' + suggestion.user.id
              "
            />
          }
          @case ("group") {
            <div
              class="suggested-item"
              [attr.data-testid]="
                dataTestId + '--search-result-group-' + suggestion.group.id
              "
            >
              <db-user-group-avatar [avatarSize]="'mini'" />
              <span class="title ml-12">
                {{ suggestion.group.name }} ({{ suggestion.group.userCount }})
              </span>
            </div>
          }
        }
      </div>
    </ng-template>
    <ng-template let-selectedItem let-i="index" pTemplate="selectedItem">
      @switch (selectedItem.type) {
        @case ("user") {
          <db-user-info
            [openProfileOnClick]="false"
            [userInfo]="selectedItem.user"
            [avatarSize]="'mini'"
            [attr.data-testid]="dataTestId + '--selected-item-user-' + i"
          />
        }
        @case ("group") {
          <div
            class="selected-item"
            [attr.data-testid]="dataTestId + '--selected-item-group-' + i"
          >
            <db-user-group-avatar [avatarSize]="'mini'" />
            <span class="title ml-12">
              {{ selectedItem.group.name }} ({{ selectedItem.group.userCount }})
            </span>
          </div>
        }
      }
    </ng-template>
    <ng-template pTemplate="removetokenicon">
      <!-- We just don't need a removetokenicon for the single select case -->
    </ng-template>
  </p-autoComplete>
</div>
