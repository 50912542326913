import { Injectable } from "@angular/core";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";
import { floorAndSpacesBundles } from "./bundles";
import { floorAndSpacesSelectors } from "./selectors";

@Injectable({ providedIn: "root" })
export class FloorsAndSpacesModel {
  selectors = connectSelectors(floorAndSpacesSelectors);
  actions = connectBundles(floorAndSpacesBundles);
}
