<div class="field">
  @if (!!label) {
    <label class="chip-list-label small-text-bold">
      {{ label }}
    </label>
  }
  <div class="chip-list-container">
    @for (chip of options; track chip) {
      <db-chip
        [label]="chip.label"
        [size]="'normal'"
        [styleClass]="
          (selectedValues | includes: chip.value) ? 'chip-db-purple' : ''
        "
        (click)="buttonClickHandler(chip.value)"
      >
      </db-chip>
    }
  </div>
</div>
