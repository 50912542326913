<div
  class="link-container"
  [attr.data-testId]="dataTestId"
  [ngStyle]="style"
  [ngClass]="{
    'is-loading': isLoading,
    'fit-content': width === 'fit-content',
    'w-full': width === 'full-width',
    'severity-secondary': severity === 'secondary',
    'severity-danger': severity === 'danger',
    'with-button-padding': withButtonPadding,
    disabled: disabled,
  }"
>
  @if (icon) {
    <div class="icon">
      <i class="pi" [ngClass]="icon"></i>
    </div>
  }
  <span>
    {{ label }}
  </span>
</div>
