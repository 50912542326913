import { Injectable } from "@angular/core";
import { UserRole } from "types/enums/user-role";
import { FeatureAccess } from "types/interfaces/feature-access";
import { FeatureAccessType, IUser } from "types";
import { ICorporateInfo } from "common-module";

@Injectable({
  providedIn: "root",
})
export class FeatureAccessRoutingService {
  /**
   * Retrieves the initial route for administration based on the user's role and feature access.
   *
   * @param authUser - The authenticated user object.
   * @param mppsEnabled - A boolean indicating if MPPS is enabled.
   * @param isDbAdmin - A boolean indicating if the user is a deskbird administrator.
   * @param featureAccess - The feature access containing the user's feature permissions.
   * @param companyBasePath - The base path for the company (e.g. /admin/company/375).
   * @returns A string representing the initial route or null if no route is determined.
   */
  getInitialRouteForAdministration(
    authUser: IUser,
    mppsEnabled: boolean,
    isDbAdmin: boolean,
    featureAccess: FeatureAccess | null,
    companyBasePath: string = "",
  ): string | null {
    const hasAccessToOfficeAdministration =
      [UserRole.ADMIN, UserRole.OFFICE_ADMIN].includes(authUser.role) ||
      isDbAdmin;
    const hasAccessToPeopleAdministration =
      hasAccessToOfficeAdministration ||
      [UserRole.MANAGER, UserRole.GROUP_MANAGER].includes(authUser.role);

    if (!mppsEnabled || !featureAccess) {
      return hasAccessToOfficeAdministration
        ? `${companyBasePath}/office`
        : hasAccessToPeopleAdministration
          ? `${companyBasePath}/people-analytics/people`
          : "/default";
    }

    if (
      hasAccessToOfficeAdministration &&
      this.hasSomeFeatureAccess(
        [FeatureAccessType.RESOURCE_BOOKING, FeatureAccessType.ROOMS],
        featureAccess,
      )
    ) {
      return `${companyBasePath}/office`;
    }

    if (
      hasAccessToOfficeAdministration &&
      this.hasSomeFeatureAccess(
        [
          FeatureAccessType.WORKFORCE_ANALYTICS,
          FeatureAccessType.OFFICE_EVENTS,
          FeatureAccessType.SCHEDULING,
          FeatureAccessType.SCHEDULING_CALENDAR_SYNC,
          FeatureAccessType.HYBRID_WORK_POLICIES,
        ],
        featureAccess,
      )
    ) {
      return `${companyBasePath}/user`;
    }

    if (
      hasAccessToPeopleAdministration &&
      this.hasSomeFeatureAccess(
        FeatureAccessType.WORKFORCE_ANALYTICS,
        featureAccess,
      )
    ) {
      return `${companyBasePath}/people-analytics/people`;
    }

    return null;
  }

  /**
   * Retrieves the initial route for a client based on feature access and corporate information.
   *
   * @param mppsEnabled - A boolean indicating if MPPS is enabled.
   * @param corporateInfo - The company information.
   * @param featureAccess - The feature access containing the user's feature permissions.
   * @returns A string representing the initial route.
   */
  getInitialRouteForClient(
    mppsEnabled: boolean,
    corporateInfo: ICorporateInfo,
    featureAccess: FeatureAccess | null,
  ): string {
    if (!mppsEnabled || !featureAccess) {
      return corporateInfo?.allowsScheduling
        ? "/planning"
        : corporateInfo?.allowsResourceBooking
          ? "/office"
          : "/no-access";
    }

    return this.hasSomeFeatureAccess(
      FeatureAccessType.SCHEDULING,
      featureAccess,
    )
      ? "/planning"
      : "/office";
  }

  private hasSomeFeatureAccess(
    feature: FeatureAccessType | FeatureAccessType[],
    featureAccess: FeatureAccess,
  ): boolean {
    const features = Array.isArray(feature) ? feature : [feature];
    return features.some((f) => featureAccess.features.includes(f));
  }
}
