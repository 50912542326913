<db-card>
  @if (showLottie$ | async) {
    <db-lottie-player
      [height]="'165px'"
      [lottie]="Lottie.CHECK_IN"
      margin="90px auto 0"
    ></db-lottie-player>
  }
  <div class="form-container">
    <div class="login-title">
      <h2 i18n="@@auth-module|check-in|sign-in-greeting">
        Sign in to deskbird
      </h2>
    </div>
    <form #form="ngForm" autocomplete="off" (ngSubmit)="submitForm(form)">
      <div class="w-full">
        <input
          name="email"
          #email="ngModel"
          type="text"
          [ngModel]="formValue.email"
          dbTrim
          required
          email
          class="w-full"
          placeholder="Enter your work email"
          i18n-placeholder="@@auth-module|check-in|work-email-placeholder"
          pInputText
        />

        @if (email.touched && email.errors && email.errors["required"]) {
          <small
            class="p-error block"
            i18n="@@auth-module|check-in|email-required"
          >
            Email is required
          </small>
        }
        @if (email.touched && email.errors && email.errors["email"]) {
          <small
            class="p-error block"
            i18n="@@auth-module|check-in|email-invalid"
          >
            Please enter a valid email address
          </small>
        }

        <div class="terms-container">
          <span
            class="very-small-text"
            i18n="@@auth-module|check-in|see-terms-policy"
          >
            See our
            <a href="https://www.deskbird.com/legal" target="_blank"
              >Terms of Service & Privacy Policy</a
            >
          </span>
        </div>
      </div>
      <div class="buttons-container">
        <db-button
          [isSubmit]="true"
          width="full-width"
          [disabled]="form.invalid || isLoading"
        >
          <db-loading-tense
            presentText="Loading"
            infinitiveText="Sign in"
            [state]="
              isLoading
                ? LoadingTenseState.Present
                : LoadingTenseState.Infinitive
            "
            i18n-presentText="@@auth-module|check-in|loader-present-text"
            i18n-infinitiveText="
              @@auth-module|check-in|loader-infinitive-check-in-text"
          >
          </db-loading-tense>
        </db-button>
      </div>
    </form>
  </div>
</db-card>
