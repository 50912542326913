<db-progress-bar [dbHidden]="!showProgressLoader"></db-progress-bar>

<div class="db-container" [ngClass]="contentBackgroundColor">
  <div
    class="header-container"
    [ngStyle]="headerStyle"
    [ngClass]="headerBackgroundColor"
  >
    <div class="title">
      <db-button-icon
        icon="pi pi-times"
        size="x-large"
        data-testid="common--close-slide-over--button"
        (click)="closeAction.emit()"
      >
      </db-button-icon>

      <h3>
        @if (!!titleTemplate) {
          <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
        } @else {
          {{ title }}
        }
      </h3>
    </div>
    <div class="header-template">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
  </div>
  <div class="content-container" [ngClass]="contentBackgroundColor">
    <ng-content></ng-content>
  </div>
</div>
