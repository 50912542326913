import { Injectable } from "@angular/core";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";
import { Observable, filter, map } from "rxjs";
import { FeatureFlag } from "types";
import { FEATURE_FLAG_DEFAULT_VALUE } from "../../constants-feature-flags";
import { featureFlagBundles } from "./bundles";
import { featureFlagSelectors } from "./selectors";

@Injectable({ providedIn: "root" })
export class FeatureFlagModel {
  selectors = connectSelectors(featureFlagSelectors);
  actions = connectBundles(featureFlagBundles);

  featureEnableBookingForGuests$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_BOOKING_FOR_GUESTS,
  );
  featureEnableSchedulingCalendarSync$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_SCHEDULING_CALENDAR_SYNC,
  );
  featureEnableBookingLimit50$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_BOOKING_LIMIT_50,
  );
  featureEnabledMyTeamManagement$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_MY_TEAMS_MANAGEMENT,
  );
  featureFlagEnableHybridWorkPoliciesPreview$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_HYBRID_WORK_POLICIES_PREVIEW,
  );
  featureFlagEnableFollowUserButton$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_FOLLOW_USER_BUTTON,
  );
  featureFlagEnableDailyOfficeTable$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_DAILY_OFFICE_TABLE,
  );
  featureEnableAssistant$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_ASSISTANT,
  );
  featureEnableCustomAnalytics$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_CUSTOM_ANALYTICS,
  );
  featureHideIconsInMenu$ = this.getFeatureFlagValue(
    FeatureFlag.HIDE_ICONS_IN_MENU,
  );
  featureEnableOfficeEquipment$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_OFFICE_EQUIPMENT,
  );
  featureEnableFailureOnRecurringMeetingConflict$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT,
  );
  featureEnablePublicApiKeys$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_PUBIC_API_KEYS,
  );
  featureEnableAreaExtendedNameAndDescription$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_AREA_EXTENDED_NAME_AND_DESCRIPTION,
  );
  featureEnableCateringAndServices$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_CATERING_AND_SERVICES,
  );
  featureEnableKioskMode$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_KIOSK_MODE,
  );

  featureEnableMPPS$ = this.selectors.featureFlags$.pipe(
    map((flags) => flags[FeatureFlag.ENABLE_MPPS]?.value),
    // prevent usage of mpps flag before it's initialized
    filter((value): value is boolean => value !== undefined),
  );

  featureEnableMPPSMocks$ = this.getFeatureFlagValue(
    FeatureFlag.ENABLE_MPPS_MOCKS,
  );

  private getFeatureFlagValue(flag: FeatureFlag): Observable<boolean> {
    return this.selectors.featureFlags$.pipe(
      map((flags) => flags[flag]?.value || FEATURE_FLAG_DEFAULT_VALUE),
    );
  }
}
