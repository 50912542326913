import {
  ClickFavoriteSpaceEntryPoint,
  ViewOfficeEventEntryPoint,
  InitiateEntityBookingEntryPoint,
  SubmittedEntityBookingEntryPoint,
  OfficeFunctionsEntryPoint,
  ClearFilterEntryPoint,
  FloorPlanNavigationEntryPoint,
  GtmSelectionType,
  OfficeEquipmentEntryPoint,
  SelectFilterEntryPoint,
} from "../enums";
import { DeskAreaType } from "types";

export interface GTMEvent {
  event: string;
}

export interface GtmEventPaywallShow {
  event: "show_plan_upgrade";
  entry_point: string;
  upsell_page: string;
}

export interface GtmEventPaywallClick {
  event: "click_plan_upgrade";
  entry_point: string;
  upsell_page: string;
  variant: "yes" | "no";
}

export interface GTMEventWithSyncedMeetingRoom {
  synced_meetingroom: string;
}

export interface GTMEventWithSubmittedOfficeEvent {
  invitees_category: string;
  amount_offices_invited: number;
  amount_users_and_groups_invited: number;
  location: string;
  description: string;
  icon: string;
  title: string;
  notify_invitees: boolean;
  allow_users_to_respond: boolean;
}

export interface GTMEventWithInitiateEntityBooking {
  entry_point: InitiateEntityBookingEntryPoint;
  assigned_entity: boolean;
  entity_type: DeskAreaType | "unknown";
}

export interface GTMEventWithSelectingSchedulingStatus {
  entry_point: "personal_feed" | "colleagues_view";
  status_type: string;
  status_owner: "own" | "other";
}

export interface GTMEventWithSavingSchedulingStatus {
  entry_point: "colleagues_view";
}

export interface GTMEventWithSubmittedEntityBooking {
  entry_point: SubmittedEntityBookingEntryPoint;
  floor_id: string | undefined;
  area_id: string;
  office_id: string;

  entity_type: DeskAreaType;
  multi_day_booking: boolean;
  quick_book: boolean;

  amount: number;
  amount_anonymous: number;
  amount_assigned_entities: number;
  amount_favorite_entities: number;
  amount_guest_bookings: number;
  amount_different_owner: number;
}

export interface GTMEventWithClickFavoriteSpace {
  entry_point: ClickFavoriteSpaceEntryPoint;
  /**zone_item_id */
  entity_id: string;
  favorite_status: "favorited" | "unfavorited";
  entity_type: DeskAreaType;
}

export interface GTMEventWithHrisActivationStatus {
  /** If a user is activating or deactivating an integration. */
  activation_status: boolean;
}

export interface GTMEventWithCreateOfficePolicy {
  policy_name: string;
  company_wide_policy: boolean;
  amount_policy_rules: number;
  amount_policy_users: number;
  policy_activation_date_type: string;
  policy_activation_date: string | undefined;
  policy_fixed_days: string | undefined;
  policy_rule_1_period: string | undefined;
  policy_rule_1_counter: number | undefined;
  policy_rule_1_limit_type: string | undefined;
  policy_rule_1_restriction_type: string | undefined;
  policy_rule_2_period: string | undefined;
  policy_rule_2_counter: number | undefined;
  policy_rule_2_limit_type: string | undefined;
  policy_rule_2_restriction_type: string | undefined;
}

export interface GTMEventWithOfficeEventsResponse extends GTMEvent {
  attendance_response: "confirm" | "reject" | "maybe";
}

export interface GTMEventWithOfficeEventsEntryPoint extends GTMEvent {
  entry_point: ViewOfficeEventEntryPoint;
}

export interface GTMEventQrCodeDownload extends GTMEvent {
  qr_level: "office" | "floor" | "area" | "resource";
}

export interface GTMEventOfficeFunctionsEntryPoint {
  entry_point: OfficeFunctionsEntryPoint;
}

export interface GTMEventOfficeEquipmentEntryPoint {
  entry_point: OfficeEquipmentEntryPoint;
}

export interface GTMEventOfficeEquipmentEntryPoint {
  entry_point: OfficeEquipmentEntryPoint;
}

export interface GTMEventSelectFilterEntryPoint {
  entry_point: SelectFilterEntryPoint;
}

export interface GTMEventClearFilterEntryPoint {
  entry_point: ClearFilterEntryPoint;
}

export interface GTMEventNavigateFloorPlanEntryPoint {
  entry_point: FloorPlanNavigationEntryPoint;
}

export interface GtmEvent {
  select_MR: GTMEventWithSyncedMeetingRoom;
  view_info_box: object;
  select_MR_slot: GTMEventWithSyncedMeetingRoom;
  edit_MR_start_time: GTMEventWithSyncedMeetingRoom;
  edit_MR_end_time: GTMEventWithSyncedMeetingRoom;
  edit_MR_title: GTMEventWithSyncedMeetingRoom;
  edit_MR_creator: GTMEventWithSyncedMeetingRoom;
  MR_title_changed: GTMEventWithSyncedMeetingRoom;
  select_MR_creator: GTMEventWithSyncedMeetingRoom;
  change_MR_slot: GTMEventWithSyncedMeetingRoom;
  close_MR_booking_page: GTMEventWithSyncedMeetingRoom;
  confirm_booking_upcoming_bookings: GTMEventWithSyncedMeetingRoom;
  edit_booking: GTMEventWithSyncedMeetingRoom;
  cancel_booking: GTMEventWithSyncedMeetingRoom;
  dismiss_booking_confirmation: GTMEventWithSyncedMeetingRoom;
  dismiss_booking_confirmation_open_upcoming_bookings: GTMEventWithSyncedMeetingRoom;
  booking_confirmation_upcoming_bookings: GTMEventWithSyncedMeetingRoom;

  confirm_MR_booking: object;

  edit_MR_date: object;
  select_MR_date: object;
  select_MR_start_time: object;
  select_MR_end_time: object;

  change_previous_week: object;
  change_next_week: object;
  change_weekday: object;
  change_calendar_day: object;
  select_resource: { resource_type: DeskAreaType };
  view_floorplan_MR: object;
  filter_floor: object;
  select_MR_floor: object;
  filter_MR_capacity: object;
  select_MR_capacity: object;
  filter_office: object;

  cancel_confirmation: { is_meeting_room: boolean; is_deleting_all: boolean };

  edit_MR_event: object;
  cancel_MR_event_edit: object;
  confirm_MR_event_edit: object;
  delete_MR_event: object;
  close_MR_event: object;
  view_MR_booking: object;
  cancel_MR_event_creation: object;
  recurring_MR_event: object;

  initiate_entity_booking: GTMEventWithInitiateEntityBooking;
  save_status: GTMEventWithSavingSchedulingStatus;
  select_status: GTMEventWithSelectingSchedulingStatus;
  submitted_entity_booking: GTMEventWithSubmittedEntityBooking;
  click_favorite_space_button: GTMEventWithClickFavoriteSpace;

  /* HRIS integration */
  click_hris_integration: GTMEventWithHrisActivationStatus;
  confirm_hris_integration_disconnection: object;
  dismiss_hris_integration_disconnection: object;
  select_email_settings_hris: GTMEventWithHrisActivationStatus;
  /* End of HRIS integration */

  /* Hybrid work policies */
  add_policy: object;
  confirm_custom_statuses_settings_publication: object;
  confirm_policy_deletion: object;
  confirm_policy_publication: GTMEventWithCreateOfficePolicy;
  confirm_resolving_conflicts: object;
  dismiss_custom_statuses_settings_publication: object;
  dismiss_policy_deletion: object;
  dismiss_policy_publication: object;
  dismiss_resolving_conflicts: object;
  modify_custom_statuses_settings: object;
  /* End of Hybrid work policies */

  /* Office events */
  respond_event_attendance: GTMEventWithOfficeEventsResponse;
  view_events_details: GTMEventWithOfficeEventsEntryPoint;
  confirm_event_publication: GTMEventWithSubmittedOfficeEvent;
  /* End of Office events */

  /* Qr Codes events */
  qr_code_download: GTMEventQrCodeDownload;
  /* End of qr codes events */

  view_in_office: object;
  view_people_from_other_offices: object;
  view_followed_colleagues: object;
  view_guests: object;
  switch_to_internal_employee: object;
  select_guest: object;

  /* Catering and Services */
  add_category: object;
  rearrange_category: object;
  edit_category: object;
  delete_category: object;
  disable_category: object;

  edit_category_name: object;
  add_category_item: object;
  edit_category_description: object;
  edit_provider_email: object;
  activate_deactivate_category: object;
  create_category: object;

  confirm_deactivate_category: object;
  cancel_deactivate_category: object;

  /* Daily office view */
  select_office_function: GTMEventOfficeFunctionsEntryPoint & {
    selection_type: GtmSelectionType;
    office_function:
      | "first_aider"
      | "fire_warden"
      | "key_holder"
      | "evacuation_helper";
  };

  select_equipment_item: GTMEventOfficeEquipmentEntryPoint & {
    selection_type: GtmSelectionType;
    entity_type: DeskAreaType;
  };

  select_filter: GTMEventSelectFilterEntryPoint & {
    entity_type: DeskAreaType | "";
  };

  navigate_floor_plan: GTMEventNavigateFloorPlanEntryPoint;
  clear_filter: GTMEventClearFilterEntryPoint;
}
