import { inject } from "@angular/core";
import { patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { pipe, switchMap, tap } from "rxjs";
import { tapResponse } from "@ngrx/operators";
import { IOfficeRoleOption, IOfficeRoleUpdate } from "types";
import { OfficeRolesService } from "../../services/office-roles.service";
import { NotificationModel, NotificationType } from "notification-module";

interface OfficeRolesState {
  officeRoles: IOfficeRoleOption[];
  isLoading: boolean;
  isSubmitting: boolean;
  error: string | null;
}

const initialState: OfficeRolesState = {
  officeRoles: [],
  isLoading: false,
  isSubmitting: false,
  error: null,
};

export const OfficeRolesStore = signalStore(
  { providedIn: "root" },
  withState(initialState),
  withMethods((store, service = inject(OfficeRolesService)) => {
    const notificationModel = inject(NotificationModel);

    return {
      loadOfficeRoles: rxMethod<{
        companyId: string;
        includeInactive?: boolean;
      }>(
        pipe(
          tap(() => patchState(store, { isLoading: true, error: null })),
          switchMap(({ companyId, includeInactive }) =>
            service.getOfficeRoleOptions(companyId, includeInactive).pipe(
              tapResponse({
                next: (officeRoles) => {
                  if (Array.isArray(officeRoles)) {
                    patchState(store, {
                      officeRoles,
                      isLoading: false,
                    });
                  }
                },
                error: (error: Error) => {
                  patchState(store, {
                    error: error.message,
                    isLoading: false,
                  });
                  notificationModel.actions.dispatch.showNotification({
                    data: $localize`:@@office-roles-module|error-get-office-roles:Error loading office role(s)`,
                    notificationType: NotificationType.ERROR,
                  });
                },
              }),
            ),
          ),
        ),
      ),

      updateOfficeRoles: rxMethod<{ officeRoles: IOfficeRoleUpdate[] }>(
        pipe(
          tap(() => patchState(store, { isSubmitting: true, error: null })),
          switchMap(({ officeRoles }) =>
            service.updateOfficeRoles(officeRoles).pipe(
              tapResponse({
                next: (updatedRoles) => {
                  if (Array.isArray(updatedRoles)) {
                    patchState(store, {
                      officeRoles: updatedRoles,
                      isSubmitting: false,
                    });
                  }
                },
                error: (error: Error) => {
                  patchState(store, {
                    error: error.message,
                    isSubmitting: false,
                  });
                  notificationModel.actions.dispatch.showNotification({
                    data: $localize`:@@user-module|error-update-office-roles:Error updating office roles`,
                    notificationType: NotificationType.ERROR,
                  });
                },
              }),
            ),
          ),
        ),
      ),

      resetState(): void {
        patchState(store, initialState);
      },
    };
  }),
);
