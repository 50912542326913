import {
  ChangeDetectorRef,
  Directive,
  inject,
  Input,
  OnDestroy,
} from "@angular/core";
import { SelectionItem } from "./autocomplete-users.types";
import {
  Subject,
  startWith,
  takeUntil,
  distinctUntilChanged,
  debounceTime,
  switchMap,
  of,
  Observable,
  map,
  combineLatest,
} from "rxjs";
import { IUserInfo, IUserGroupSearchResult, UserStatus } from "types";
import { IUserFilterService } from "shared-services";
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
  AutoCompleteUnselectEvent,
} from "primeng/autocomplete";

const SEARCH_DEBOUNCE_TIME = 200;
const DEFAULT_LIMIT = 100;

@Directive({
  standalone: true,
})
/** Base logic for autocomplete with users/groups for single and multi select case */
export abstract class AutocompleteUsersComponentBase implements OnDestroy {
  @Input() companyId!: string;
  @Input() placeholder: string =
    $localize`:@@user-module|user-search|user-search-placeholder:Search colleagues by name or email`;
  @Input() emptyMessage: string =
    $localize`:@@user-module|user-search|no-users:No colleagues found`;
  @Input() limit = DEFAULT_LIMIT;
  @Input() excludeUserIds: string[] = [];
  @Input() userService!: IUserFilterService;
  @Input() label?: string;
  @Input() showAsterisk = false;
  @Input() optional = false;

  @Input() enableUserSelection = true;
  @Input() enableGroupSelection = false;
  /** Defines the user statuses to be included in the autocomplete search. Filtering is available only for roles with admin access. */
  @Input() usersStatusFilter: UserStatus[] = [];

  @Input() protected abstract dataTestId: string;
  @Input() protected abstract filterDeskAreaId: string;
  @Input() abstract showClear: boolean;

  /** As prime autocomplete supports `multiple` input (true or false) BUT `selectedItem` template is not supported when `[multiple]="false"` (https://primeng.org/autocomplete#api.autocomplete.templates.selectedItem), the implementation of single selection is enforced via that custom flag */
  protected abstract allowMultipleSelectedItems: boolean;
  protected readonly destroy$ = new Subject<void>();
  protected readonly searchSubject = new Subject<string>();
  protected readonly cdRef = inject(ChangeDetectorRef);

  currentSelection: SelectionItem[] = []; // even when single select, we have to use array to keep the same interface (see allowMultipleSelectedItems)
  suggestions: SelectionItem[] = [];

  constructor() {
    this.initializeSearchSubscription();
  }

  protected abstract addSuggestions(
    users: IUserInfo[],
    groups: IUserGroupSearchResult[],
    searchQuery: string,
  ): SelectionItem[];
  protected abstract emitCurrentSelection(): void;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSearch(event: AutoCompleteCompleteEvent): void {
    this.searchSubject.next(event?.query?.trim());
  }

  onSelect(event: AutoCompleteSelectEvent): void {
    const selectedItem = event.value as SelectionItem;
    if (!this.allowMultipleSelectedItems) {
      this.currentSelection = [selectedItem];
    } else {
      this.currentSelection = [...this.currentSelection, selectedItem];
    }
    this.emitCurrentSelection();
    this.searchSubject.next("");
  }

  onUnselect(event: AutoCompleteUnselectEvent): void {
    const unselectedItem = event.value as SelectionItem;
    if (!this.allowMultipleSelectedItems) {
      this.currentSelection = [];
    } else {
      this.currentSelection = this.currentSelection.filter(
        (item) => item.key !== unselectedItem.key,
      );
    }
    this.emitCurrentSelection();
  }

  onBlur(): void {
    this.searchSubject.next("");
  }

  onClear(): void {
    this.currentSelection = [];
    this.emitCurrentSelection();
  }

  protected loadUsersById(userIds: string[]): Observable<IUserInfo[]> {
    if (!userIds.length || !this.enableUserSelection) {
      return of([]);
    }

    return this.userService
      .loadUsersForCompanyFiltered({
        companyId: this.companyId,
        userIds,
        offset: 0,
        limit: userIds.length,
        status: this.usersStatusFilter,
        deskAreaId: this.filterDeskAreaId,
      })
      .pipe(
        map((response) => response.data),
        takeUntil(this.destroy$),
      );
  }

  protected loadGroupsById(
    groupIds: string[],
  ): Observable<IUserGroupSearchResult[]> {
    if (!groupIds.length || !this.enableGroupSelection) {
      return of([]);
    }

    return this.userService
      .loadGroupsForCompanyFiltered({
        companyId: this.companyId,
        groupIds,
        include: ["userCount"],
        offset: 0,
        limit: groupIds.length,
      })
      .pipe(
        map((response) => response.data),
        takeUntil(this.destroy$),
      );
  }

  protected mapUserToSelectionItem(user: IUserInfo): SelectionItem {
    return {
      type: "user",
      key: `user-${user.id}`,
      user,
    };
  }

  protected mapGroupToSelectionItem(
    group: IUserGroupSearchResult,
  ): SelectionItem {
    return {
      type: "group",
      key: `group-${group.id}`,
      group,
    };
  }

  private initializeSearchSubscription(): void {
    this.searchSubject
      .pipe(
        startWith(""),
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((searchValue) => {
          if (!searchValue || !this.companyId) {
            return of([]);
          }

          let usersRequest$: Observable<IUserInfo[]> = of([]);
          if (this.enableUserSelection) {
            usersRequest$ = this.userService
              .loadUsersForCompanyFiltered({
                companyId: this.companyId,
                searchQuery: searchValue,
                excludeUserIds: [
                  ...this.excludeUserIds,
                  ...this.getSelectedUserIds(),
                ],
                offset: 0,
                limit: this.limit,
                status: this.usersStatusFilter,
                deskAreaId: this.filterDeskAreaId,
              })
              .pipe(map((response) => response.data));
          }

          let groupsRequest$: Observable<IUserGroupSearchResult[]> = of([]);
          if (this.enableGroupSelection) {
            groupsRequest$ = this.userService
              .loadGroupsForCompanyFiltered({
                companyId: this.companyId,
                searchQuery: searchValue,
                excludeGroupIds: this.getSelectedGroupIds(),
                include: ["userCount"],
                offset: 0,
                limit: this.limit,
              })
              .pipe(map((response) => response.data));
          }

          return combineLatest([usersRequest$, groupsRequest$]).pipe(
            map(([users, groups]) =>
              this.addSuggestions(users, groups, searchValue),
            ),
          );
        }),
      )
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.cdRef.detectChanges();
      });
  }

  private getSelectedUserIds(): string[] {
    if (!this.currentSelection) {
      return [];
    }

    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "user" }> =>
          item.type === "user",
      )
      .map((item) => item.user.id!);
  }

  private getSelectedGroupIds(): string[] {
    if (!this.currentSelection) {
      return [];
    }

    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "group" }> =>
          item.type === "group",
      )
      .map((item) => item.group.id);
  }
}
