import { forNamespace } from "ngrx-action-bundles";
import {
  IUserGroup,
  IExternalGoogleMeetingRoom,
  IExternalMicrosoftMeetingRoom,
} from "common-module";
import { IDeskAreaCreationData } from "../../interfaces-and-types";
import { IUser, IDeskAreaItem, IDeskArea, IOfficeFloor } from "types";
import { props } from "@ngrx/store";

const factory = forNamespace("FLOORS AND SPACES");

export const setSelectedFloorIndexBundle = factory.singleAction(
  "setSelectedFloorIndex",
  props<{ floorIndex: number }>(),
);
export const clearBundle = factory.singleAction("clear");
export const updateFloorImageBundle = factory.singleAction(
  "updateFloorImage",
  props<{ floorId: string; image: string }>(),
);

export const setFloorConfigReadyStateBundle = factory.asyncAction(
  "setFloorConfigReadyState",
  props<{ value: boolean; officeId: string; floorId: string }>(),
  props<{ value: boolean; officeId: string; floorId: string }>(),
  props<{ error: any; officeId: string; floorId: string }>(),
);

export const loadFloorConfigBundle = factory.asyncActionWithCleanup(
  "loadFloorConfig",
  props<{ officeId: string; groupId: string }>(),
  props<{ floorConfig: string }>(),
  props<{ error: any }>(),
);

export const loadFloorDataBundle = factory.asyncAction(
  "loadFloorData",
  props<{ officeId: string }>(),
  props<{ floors: IOfficeFloor[] }>(),
  props<{ error: any }>(),
);

export const saveFloorDataBundle = factory.asyncAction(
  "saveFloorData",
  props<{ officeId: string; floors: IOfficeFloor[]; updateItems?: boolean }>(),
  props<{ floors: IOfficeFloor[] }>(),
  props<{ error: any }>(),
);

export const saveZoneDataBundle = factory.asyncAction(
  "saveZoneData",
  props<{ officeId: string; floorId: string; zone: IDeskArea }>(),
  props<{ floorId: string; zone: IDeskArea }>(),
  props<{ floorId: string; error: any }>(),
);

export const saveZoneItemDataBundle = factory.asyncAction(
  "saveZoneItemData",
  props<{
    officeId: string;
    floorId: string;
    zoneId: string;
    zoneItemId: string;
    zoneItem: Partial<IDeskAreaItem>;
  }>(),
  props<{ floorId: string; zoneId: string; zoneItem: IDeskAreaItem }>(),
  props<{ floorId: string; error: any }>(),
);

export const loadUserGroupsBundle = factory.asyncAction(
  "loadUserGroups",
  props<{ companyId: string }>(),
  props<{ userGroups: IUserGroup[] }>(),
  props<{ error: any }>(),
);

export const loadUsersForCompanyBundle = factory.asyncAction(
  "loadUsersForCompany",
  props<{ companyId: string }>(),
  props<{ users: IUser[] }>(),
  props<{ error: any }>(),
);

export const loadExternalMicrosoftMeetingRoomsBundle = factory.asyncAction(
  "loadExternalMicrosoftMeetingRooms",
  undefined,
  props<{ meetingRooms: IExternalMicrosoftMeetingRoom[] }>(),
  props<{ error: any }>(),
);

export const loadExternalGoogleMeetingRoomsBundle = factory.asyncAction(
  "loadExternalGoogleMeetingRooms",
  undefined,
  props<{ meetingRooms: IExternalGoogleMeetingRoom[] }>(),
  props<{ error: any }>(),
);

export const createDeskAreaBundle = factory.asyncAction(
  "createDeskArea",
  props<{ data: IDeskAreaCreationData; officeId: string }>(),
  props<{ deskArea: IDeskArea }>(),
  props<{ error: any }>(),
);

export const reSyncMeetingRoomBundle = factory.asyncAction(
  "reSyncMeetingRoom",
  props<{ meetingRoomId: string }>(),
  props<{ meetingRoomId: string }>(),
  props<{ meetingRoomId: string; error: any }>(),
);

export const floorAndSpacesBundles = [
  setSelectedFloorIndexBundle,

  clearBundle,
  updateFloorImageBundle,
  setFloorConfigReadyStateBundle,
  loadFloorDataBundle,
  saveFloorDataBundle,
  saveZoneDataBundle,
  saveZoneItemDataBundle,
  loadUserGroupsBundle,
  loadUsersForCompanyBundle,
  loadExternalMicrosoftMeetingRoomsBundle,
  loadExternalGoogleMeetingRoomsBundle,
  createDeskAreaBundle,
  loadFloorConfigBundle,
  reSyncMeetingRoomBundle,
];
