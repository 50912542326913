import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  IApiErrorResponse,
  IUserOfficeRole,
  IOfficeRoleOption,
  IOfficeRoleUpdate,
} from "types";

@Injectable({
  providedIn: "root",
})
export class OfficeRolesService {
  constructor(private http: HttpClient) {}

  getForOffice(
    officeId: string,
  ): Observable<IUserOfficeRole[] | IApiErrorResponse> {
    return this.http.get<IUserOfficeRole[] | IApiErrorResponse>(
      `/api/offices/${officeId}/officeRoles`,
    );
  }

  getForUsers(
    userIds: string[],
  ): Observable<IUserOfficeRole[] | IApiErrorResponse> {
    return this.http.get<IUserOfficeRole[] | IApiErrorResponse>(
      `/api/users/officeRoles?userIds=${userIds.join(",")}`,
    );
  }

  updateOfficeRoles(
    officeRoles: IOfficeRoleUpdate[],
  ): Observable<IOfficeRoleOption[] | IApiErrorResponse> {
    return this.http.patch<IOfficeRoleOption[] | IApiErrorResponse>(
      `/api/officeRoles/batch`,
      officeRoles,
    );
  }

  getOfficeRoleOptions(
    companyId: string,
    includeInactive: boolean = true,
  ): Observable<IOfficeRoleOption[] | IApiErrorResponse> {
    return this.http.get<IOfficeRoleOption[] | IApiErrorResponse>(
      `/api/officeRoles?companyId=${companyId}&includeInactive=${includeInactive}`,
    );
  }
}
