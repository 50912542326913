<db-card>
  <div id="progress">
    @if (isUploading$ | async) {
      <db-progress-bar></db-progress-bar>
    }
  </div>
  <div class="wrapper">
    @if (isOnSignUpPage$ | async) {
      <h3>
        @if (!(currentUserData$ | async)?.userExists) {
          <ng-container i18n="@@auth-module|welcome">
            Welcome to {{ (currentUserData$ | async)?.companyName }}
          </ng-container>
        } @else {
          <ng-container i18n="@@auth-module|welcome-back"
            >Welcome back to {{ (currentUserData$ | async)?.companyName }}
          </ng-container>
        }
      </h3>
    }
    <div
      class="company-logo"
      [ngClass]="{ 'creating-profile': isOnSignUpPage$ | async }"
      [ngStyle]="{
        'background-image':
          'url(' + (currentUserData$ | async)?.companyLogoUrl + ')',
        'background-position': 'center',
      }"
    ></div>
    @if (isOnSignUpPage$ | async) {
      <div class="email">
        <img src="/assets/icons/purple-check-mark.svg" alt="Check" />
        <p>{{ (currentUserData$ | async)?.email }}</p>
      </div>
    }
    @switch (page$ | async) {
      @default {
        <db-user-names
          title="Enter your name"
          [firstName]="userData.firstName"
          [lastName]="userData.lastName"
          i18n-title="@@auth-module|sign-up-wizard|enter-name"
          (setNames)="enterName($event)"
        >
        </db-user-names>
      }
      @case ("set-password") {
        <form
          #form="ngForm"
          autocomplete="off"
          ngClass="sign-up-wizard"
          (ngSubmit)="register(form)"
        >
          <div class="row">
            <h2 i18n="@@auth-module|sign-up-wizard|choose-password">
              Choose a password
            </h2>
            <input
              name="password"
              #password="ngModel"
              type="password"
              minlength="8"
              dbPasswordValidator
              pInputText
              required
              placeholder="Password"
              ngModel
              i18n-placeholder="@@auth-module|password"
            />
            <div class="requirements">
              <div class="requirements-element">
                <img
                  class="checkmark"
                  src="assets/icons/checkmark-bgn.svg"
                  [ngClass]="{
                    opacity: !password.errors?.['required']
                      ? (password.errors | exec: hasOpacity : 'minlength')
                      : true,
                  }"
                />
                <img
                  class="checkmark-tick"
                  src="assets/icons/white-checkmark.svg"
                  alt="checkmark"
                />
                <p i18n="@@auth-module|sign-up-wizard|message-8-characters">
                  At least 8 characters
                </p>
              </div>
              <div class="requirements-element">
                <img
                  class="checkmark"
                  src="assets/icons/checkmark-bgn.svg"
                  [ngClass]="{
                    opacity: !password.errors?.['required']
                      ? (password.errors
                        | exec: hasOpacity : 'hasMixOfLettersAndNumbers')
                      : true,
                  }"
                />
                <img
                  class="checkmark-tick"
                  src="assets/icons/white-checkmark.svg"
                  alt="checkmark"
                />
                <p i18n="@@auth-module|sign-up-wizard|message-mixed-characters">
                  A mix of letters and numbers
                </p>
              </div>
              <div class="requirements-element">
                <img
                  class="checkmark"
                  src="assets/icons/checkmark-bgn.svg"
                  [ngClass]="{
                    opacity: !password.errors?.['required']
                      ? (password.errors | exec: hasOpacity : 'hasOneUpperCase')
                      : true,
                  }"
                />
                <img
                  class="checkmark-tick"
                  src="assets/icons/white-checkmark.svg"
                  alt="checkmark"
                />
                <p i18n="@@auth-module|sign-up-wizard|message-upper-case">
                  At least one upper case
                </p>
              </div>
              <div class="requirements-element">
                <img
                  class="checkmark"
                  src="assets/icons/checkmark-bgn.svg"
                  [ngClass]="{
                    opacity: !password.errors?.['required']
                      ? (password.errors | exec: hasOpacity : 'hasOneSpecial')
                      : true,
                  }"
                />
                <img
                  class="checkmark-tick"
                  src="assets/icons/white-checkmark.svg"
                  alt="checkmark"
                />
                <p
                  i18n="@@auth-module|sign-up-wizard|message-special-character"
                >
                  At least one special character
                </p>
              </div>
            </div>
          </div>
          <db-button
            [isSubmit]="true"
            width="full-width"
            [disabled]="form.invalid || isLoading"
          >
            <db-loading-tense
              presentText="Signing up"
              infinitiveText="Confirm"
              [state]="
                isLoading
                  ? LoadingTenseState.Present
                  : LoadingTenseState.Infinitive
              "
              i18n-presentText="@@auth-module|sign-up-loader-present-text"
              i18n-infinitiveText="@@common|confirm"
            >
            </db-loading-tense>
          </db-button>
        </form>
      }
      @case ("upload-avatar") {
        <h3>
          <ng-container i18n="@@auth-module|sign-up-wizard|add-picture"
            >Add a profile picture</ng-container
          >
        </h3>
        <div class="profile-image">
          @if (profileImage$ | async; as imageUrl) {
            <div
              class="user-avatar"
              [ngStyle]="{ background: 'url(' + imageUrl + ')' }"
            ></div>
          } @else {
            <div
              class="user-initials"
              [ngStyle]="{ background: (avatarColor$ | async) || '#5c639a' }"
            >
              {{ initials$ | async }}
            </div>
          }
          <ng-template #initials>
            <div
              class="user-initials"
              [ngStyle]="{ background: (avatarColor$ | async) || '#5c639a' }"
            >
              {{ initials$ | async }}
            </div>
          </ng-template>
          <div class="flex justify-center">
            <div class="upload-input menu-trigger" (click)="op.toggle($event)">
              <img
                src="/assets/icons/upload-button.svg"
                alt="Upload photo"
                i18n-alt="@@common|upload-photo"
              />
            </div>
            <p-overlayPanel [appendTo]="'body'" #op>
              <db-upload-button
                (uploadResult)="handleUpload($event)"
                [accept]="acceptedImageFormats"
                [disabled]="!!(isUploading$ | async)"
                [location]="avatarLocation"
                uuid="sign-up-avatar-upload"
              >
                <div i18n="@@common|upload-file">Upload file</div>
              </db-upload-button>
              @if (profileImage$ | async) {
                <db-button-link
                  [disabled]="(isUploading$ | async)!"
                  [withButtonPadding]="true"
                  (click)="removeAvatar()"
                  i18n-label="@@auth-module|sign-up-wizard|remove-picture"
                  label="Remove current photo"
                >
                </db-button-link>
              }
            </p-overlayPanel>
          </div>
        </div>
        <div>
          <db-button
            width="full-width"
            (click)="confirmProfile()"
            [disabled]="!!(isUploading$ | async)"
            i18n="@@common|confirm"
          >
            Confirm
          </db-button>
        </div>
      }
    }
    <a (click)="goBackToLogin()" class="big-link">
      @if (isOnSignUpPage$ | async) {
        <ng-container i18n="@@auth-module|sign-up-wizard|go-back-to-login"
          >Go back to login</ng-container
        >
      } @else {
        <ng-container i18n="@@auth-module|go-back">Go back</ng-container>
      }
    </a>
  </div>
</db-card>
