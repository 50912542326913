import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { filter, map, Observable, take } from "rxjs";
import {
  AuthModel,
  FeatureAccessRoutingService,
  FeatureAccessService,
  FeatureFlagModel,
} from "auth-module";
import { combineLatestForFrame } from "shared";

@Injectable({ providedIn: "root" })
export class DefaultProfileActivate {
  private router = inject(Router);
  private authModel = inject(AuthModel);
  private featureAccessRoutingService = inject(FeatureAccessRoutingService);
  private featureAccessService = inject(FeatureAccessService);
  private featureFlagModel = inject(FeatureFlagModel);

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> {
    return combineLatestForFrame([
      this.authModel.corporateInfo$.pipe(filter(Boolean)),
      this.featureFlagModel.featureEnableMPPS$,
      this.featureAccessService.featureAccess$,
    ]).pipe(
      map(([corporateInfo, enableMpps, featureAccess]) => {
        const profileId = route.params?.["profileId"];
        const initialRoute =
          this.featureAccessRoutingService.getInitialRouteForClient(
            enableMpps,
            corporateInfo,
            featureAccess,
          );

        return this.router.parseUrl(
          profileId
            ? `${initialRoute}(user:profile/${profileId})`
            : initialRoute,
        );
      }),
      take(1),
    );
  }
}
