import { Directive, HostListener } from "@angular/core";
import { IHoverItem } from "./hover.interface";

@Directive({
  selector: "[dbHoverContainer]",
  standalone: true,
  exportAs: "dbHoverContainer",
})
export class HoverContainerDirective {
  private hoverEffectItems = new Map<HTMLElement, IHoverItem>();

  isHovered = false;

  registerHoverItem = (item: IHoverItem): void => {
    this.hoverEffectItems.set(item.elementRef.nativeElement, item);
  };

  unregisterHoverItem(item: IHoverItem): void {
    this.hoverEffectItems.delete(item.elementRef.nativeElement);
  }

  @HostListener("mouseover")
  mouseover() {
    this.setHoverState(true);
  }

  @HostListener("mouseout")
  mouseout() {
    this.setHoverState(false);
  }

  private setHoverState(isHovered: boolean) {
    this.isHovered = isHovered;
    Array.from(this.hoverEffectItems.values()).forEach((value) => {
      value.isHovered = isHovered;
    });
  }
}
