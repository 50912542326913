import { IUserInfo } from "types/interfaces/user-info";

export const usersMock: Partial<IUserInfo>[] = [
  {
    id: "1",
    firstName: "John",
    lastName: "Doe",
    email: "johndoe@example.com",
    avatarColor: "rgb(138, 192, 124)",
  },
  {
    id: "2",
    firstName: "Salome",
    lastName: "Zhang",
    email: "sza@deskbird.app",
    profileImage:
      "https://storage.googleapis.com/deskbird-prod.appspot.com/users/avatars/1",
  },
  {
    id: "3",
    firstName: "Roberto",
    lastName: "Giordano",
    email: "rg@deskbird.app",
    profileImage:
      "https://storage.googleapis.com/deskbird-prod.appspot.com/users/avatars/2",
  },
  {
    id: "4",
    firstName: "Michelle",
    lastName: "Andersson",
    email: "mand@deskbird.app",
    profileImage:
      "https://storage.googleapis.com/deskbird-prod.appspot.com/users/avatars/3",
  },
  {
    id: "5",
    firstName: "Eduardo",
    lastName: "Hernandez",
    email: "eherd@deskbird.app",
    profileImage:
      "https://storage.googleapis.com/deskbird-prod.appspot.com/users/avatars/4",
  },
];
