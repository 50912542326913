import { NotificationType } from "./enums";

export const NOTIFICATION_STORE_NAME = "notification";

export const notificationTypeIconNames = {
  [NotificationType.DEFAULT]: "svg-neutral-toast",
  [NotificationType.WARNING]: "svg-warning-sign",
  [NotificationType.ERROR]: "svg-delete-sign-white",
  [NotificationType.SUCCESS]: "svg-success-checkmark",
};
