<div class="container-empty-state" [class.white]="isWhite">
  <img [height]="imageHeight()" [src]="imageSrc" [alt]="title" />
  <h4 class="text-center" [innerHTML]="title"></h4>
  <span class="normal-text">
    @if (body) {
      {{ body }}
    }
    <ng-content></ng-content
  ></span>
  @if (action) {
    <div class="action-container">
      <db-button (click)="actionClick.emit()">
        {{ action }}
        @if (actionIcon()) {
          &nbsp;
          <i class="pi {{ actionIcon() }}"></i>
        }
      </db-button>
    </div>
  }
</div>
