import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UserStatus,
  IUserInfo,
  IUser,
  IUserViewAvatarProfileOpeningProps,
  SkeletonLoaderType,
  IGuestUserInfo,
} from "types";
import { canOpenProfile } from "shared-utils";
import { UserAvatarComponent } from "../user-avatar";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "primeng/tooltip";
import { SkeletonLoaderComponent } from "../skeleton-loader";
import { GuestAvatarComponent } from "../guest-avatar";
import { UserNamePipe } from "shared-pipes";

const primeImports = [TooltipModule];

@Component({
  selector: "db-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    UserAvatarComponent,
    GuestAvatarComponent,
    SkeletonLoaderComponent,
    UserNamePipe,
    ...primeImports,
  ],
})
export class UserInfoComponent {
  readonly UserStatus = UserStatus;
  readonly SkeletonLoaderType = SkeletonLoaderType;

  @ViewChild(UserAvatarComponent) userAvatarComponent!: UserAvatarComponent;

  @Input() userInfo!: IUserInfo;
  @Input() guestUser?: IGuestUserInfo;
  @Input() isAnonymous = false;
  @Input() showUserEmail = false;
  @Input() isUserEmailOnSameLine = false;
  @Input() isUserEmailLighterGray = false;
  @Input() showExtra = false;
  @Input() showInactive = false;
  @Input() showEmailIfNameNotAvailable = false;
  @Input() trimmedName = false;
  @Input() isLoading = false;

  @Input() extraInfoStyles = {};
  @Input() isExtraInfoBold = false;
  @Input() userNameStyles = {};
  @Input() userNameClasses: string[] = [];
  @Input() guestDetailsClasses: string[] = [];
  @Input() guestLabel = $localize`:@@common|guest-with-brackets:(Guest)`;

  @Input() onlyImage = false;
  @Input() fullWidth = false;
  @Input() containerPadding = "";
  @Input() hideAvatar: boolean = false;
  @Input() avatarSize:
    | "micro"
    | "micro-no-border"
    | "mini"
    | "mini-no-border"
    | "standard"
    | "standard-no-border"
    | "x-mini"
    | "x-large-no-border" = "mini-no-border";
  @Input() boldName = false;
  @Input() showFavUserIcon = false;
  @Input() openProfileOnClick = false;
  @Input() openProfileOnRowClick = false;
  @Input() authUser: IUser | null = null;
  @Input() containerDataTestId = "";
  @Input() avatarDataTestId = "";
  @Input() ignoreEmailMaxWidth = false;

  @Input() whiteText = false;
  @Input() largeName = false;

  @Output() profileOpening = new EventEmitter<{
    event: Event;
    userInfo: IUserViewAvatarProfileOpeningProps;
  }>();

  get isDeletedUser(): boolean {
    return !this.isLoading && !this.userInfo;
  }

  get canOpenProfileRowOnClick(): boolean {
    return this.openProfileOnRowClick && this.canOpenProfile;
  }

  get rowTooltip(): string {
    if (!this.openProfileOnRowClick) {
      return "";
    }
    return this.userAvatarComponent?.avatarTooltip || "";
  }

  get canOpenProfile(): boolean {
    return (
      this.openProfileOnClick && canOpenProfile(this.userInfo, this.authUser)
    );
  }

  get allUserNameClasses(): string[] {
    const classes = [this.boldName ? "small-text-bold" : "small-text"];
    if (this.whiteText) {
      classes.push("white");
    }
    if (this.largeName) {
      classes.push("large");
    }
    return classes.concat(this.userNameClasses);
  }

  openProfileHandler(event: Event) {
    this.profileOpening.emit({ event, userInfo: this.userInfo });
  }
}
