@if (isLoading) {
  <div class="db-container" [ngClass]="{ fixed: isFixed }">
    <div
      data-testid="loader--loader-container"
      class="loader-container"
      [ngClass]="{ 'no-shadow': noShadow }"
    >
      <db-lottie-player
        [lottie]="loaderLottie"
        width="180px"
        height="180px"
        [loop]="true"
        [autoplay]="true"
      >
      </db-lottie-player>
      @if (loaderText) {
        <p class="small-text-bold">
          <db-dot-loader>{{ loaderText }}</db-dot-loader>
        </p>
      }
    </div>
  </div>
}
