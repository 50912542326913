import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  inject,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ChipComponent } from "../chip";
import { IncludesPipe } from "shared-pipes";

@Component({
  selector: "db-chip-list",
  standalone: true,
  imports: [ChipComponent, IncludesPipe],
  templateUrl: "./chip-list.component.html",
  styleUrls: ["./chip-list.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChipListComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipListComponent implements ControlValueAccessor {
  @Input() options: { label: string; value: string }[] = [];
  @Input() multiple = true;
  @Input() label = "";

  private onChange: (value: string[] | string | null) => void = () => {};
  private onTouch: () => void = () => {};
  cd = inject(ChangeDetectorRef);
  selectedValues: string[] = [];

  registerOnChange(fn: (value: string[] | string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(obj: string[] | string | null): void {
    this.selectedValues = Array.isArray(obj) ? obj : obj ? [obj] : [];
    this.cd.markForCheck();
  }

  buttonClickHandler(value: string): void {
    if (this.multiple) {
      if (this.selectedValues.includes(value)) {
        this.selectedValues = this.selectedValues.filter((v) => v !== value);
      } else {
        this.selectedValues = [...this.selectedValues, value];
      }
      this.onChange(this.selectedValues);
    } else {
      this.selectedValues = value ? [value] : [];
      this.onChange(value);
    }
    this.onTouch();
  }
}
