import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  FormsModule,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { TooltipModule } from "primeng/tooltip";
import { takeUntil, combineLatest, of, Observable } from "rxjs";
import {
  IUserGroupSearchResult,
  IUserInfo,
  SimpleChangesTyped,
  UserStatus,
} from "types";
import { AvatarChipComponent } from "../../avatar-chip/avatar-chip.component";
import { UserInfoComponent } from "../../user-info/user-info.component";
import {
  SelectionItem,
  FormSelectionItem,
} from "./../autocomplete-users.types";
import { AutocompleteUsersComponentBase } from "../autocomplete-users-base";
import { UserGroupAvatarComponent } from "../../user-group-avatar";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

@Component({
  selector: "db-autocomplete-users",
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    AvatarChipComponent,
    UserGroupAvatarComponent,
    UserInfoComponent,
    TooltipModule,
  ],
  templateUrl: "./autocomplete-users.component.html",
  styleUrls: ["./autocomplete-users.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AutocompleteUsersComponent,
      multi: true,
    },
  ],
})
/**
 * AutocompleteUsersComponent provides two interfaces for multiple user/group selection:
 *
 * 1. Direct Usage Interface:
 *    - Uses initiallySelected* inputs (initiallySelectedUsers, initiallySelectedGroups, etc.)
 *    - Emits full SelectionItem objects via selectionChange output
 *    - Contains complete user/group information in the emitted data
 *    - Best for simple scenarios where you need the full user/group data immediately
 *
 * 2. Reactive Forms Interface:
 *    - Uses FormSelectionItem which only contains IDs/emails
 *    - Lighter weight for form state management
 *    - Automatically loads full user/group data when form value is set
 *    - Best for forms where you only need to store IDs/emails
 *
 * Example of Direct Usage:
 * ```html
 * <db-autocomplete-users
 *   [initiallySelectedUsers]="users"
 *   [initiallySelectedGroups]="groups"
 *   (selectionChange)="onSelectionChange($event)"
 * />
 * ```
 *
 * Example of Reactive Forms Usage:
 * ```html
 * <db-autocomplete-users
 *   formControlName="selection"
 * />
 * ```
 * ```typescript
 * form = new FormGroup({
 *   selection: new FormControl<FormSelectionItem[]>([])
 * });
 * ```
 */
export class AutocompleteUsersComponent
  extends AutocompleteUsersComponentBase
  implements OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() enableExternalEmailSelection = false;
  @Input() enableGroupExpand = false;
  @Input() hideSelected = false;

  @Input() initiallySelectedUsers: IUserInfo[] = [];
  @Input() initiallySelectedUsersIds: string[] = [];
  @Input() initiallySelectedGroups: IUserGroupSearchResult[] = [];
  @Input() initiallySelectedGroupsIds: string[] = [];
  @Input() initiallySelectedExternalEmails: string[] = [];

  @Input() override dataTestId: string = "autocomplete-users-multi-select";
  @Input() override filterDeskAreaId: string = "";
  @Input() override showClear: boolean = true;

  @Output() selectionChange = new EventEmitter<SelectionItem[]>();

  private initiallySelectedUserIdsTriggered = false;
  private initiallySelectedGroupsIdsTriggered = false;
  protected override allowMultipleSelectedItems: boolean = true;
  protected groupTooltipContent: { [groupId: string]: string } = {};
  protected groupTooltipDisplayUserLimit = 20;
  protected groupTooltipExpandUserLimit = 20;

  ngOnChanges(changes: SimpleChangesTyped<AutocompleteUsersComponent>): void {
    if (
      changes.initiallySelectedUsers ||
      changes.initiallySelectedUsersIds ||
      changes.initiallySelectedGroups ||
      changes.initiallySelectedGroupsIds ||
      changes.initiallySelectedExternalEmails
    ) {
      this.initializeSelectedItems();
    }
  }

  private initializeSelectedItems(): void {
    this.initializeSelectedUsers();
    this.initializeSelectedGroups();
    this.initializeSelectedExternalEmails();
    this.emitCurrentSelection();
  }

  private initializeSelectedUsers(): void {
    if (
      this.initiallySelectedUsersIds.length > 0 &&
      !this.initiallySelectedUserIdsTriggered &&
      this.companyId &&
      this.enableUserSelection
    ) {
      this.initiallySelectedUserIdsTriggered = true;
      this.loadUsersById(this.initiallySelectedUsersIds).subscribe((users) => {
        this.currentSelection = [
          ...this.currentSelection,
          ...users.map(this.mapUserToSelectionItem.bind(this)),
        ];
        this.emitCurrentSelection();
      });
    }

    if (this.initiallySelectedUsers.length > 0 && this.enableUserSelection) {
      this.currentSelection = [
        ...this.currentSelection,
        ...this.initiallySelectedUsers.map(
          this.mapUserToSelectionItem.bind(this),
        ),
      ];
    }
  }

  private initializeSelectedGroups(): void {
    if (
      this.initiallySelectedGroupsIds.length > 0 &&
      !this.initiallySelectedGroupsIdsTriggered &&
      this.enableGroupSelection
    ) {
      this.initiallySelectedGroupsIdsTriggered = true;
      this.loadGroupsById(this.initiallySelectedGroupsIds).subscribe(
        (groups) => {
          this.currentSelection.push(
            ...groups.map(this.mapGroupToSelectionItem.bind(this)),
          );
          this.emitCurrentSelection();
        },
      );
    }

    if (this.initiallySelectedGroups.length > 0 && this.enableGroupSelection) {
      this.currentSelection.push(
        ...this.initiallySelectedGroups.map(
          this.mapGroupToSelectionItem.bind(this),
        ),
      );
    }
  }

  private initializeSelectedExternalEmails(): void {
    if (
      this.initiallySelectedExternalEmails.length > 0 &&
      this.enableExternalEmailSelection
    ) {
      this.currentSelection.push(
        ...this.initiallySelectedExternalEmails.map(
          this.mapEmailToSelectionItem.bind(this),
        ),
      );
    }
  }

  override addSuggestions(
    users: IUserInfo[],
    groups: IUserGroupSearchResult[],
    searchQuery: string,
  ): SelectionItem[] {
    const suggestedUsers = users.map(this.mapUserToSelectionItem.bind(this));
    const suggestedGroups = groups.map(this.mapGroupToSelectionItem.bind(this));

    // If the searched email matches no user, add it as an external email
    const suggestedEmails: SelectionItem[] = [];
    if (this.enableExternalEmailSelection && suggestedGroups.length === 0) {
      const selectedEmails = this.getSelectedEmails();
      if (
        EMAIL_REGEX.test(searchQuery) &&
        !selectedEmails.includes(searchQuery.toLowerCase())
      ) {
        suggestedEmails.push(this.mapEmailToSelectionItem(searchQuery));
      }
    }

    return [...suggestedGroups, ...suggestedUsers, ...suggestedEmails];
  }

  override emitCurrentSelection(): void {
    // For direct usage via selectionChange output
    this.selectionChange.emit(this.currentSelection);

    // For reactive forms - convert SelectionItem to FormSelectionItem
    const formValue: FormSelectionItem[] = this.currentSelection.map((item) => {
      switch (item.type) {
        case "user":
          return {
            type: "user" as const,
            id: this.useUuid ? item.user.uuid! : item.user.id!,
          };
        case "group":
          return {
            type: "group" as const,
            id: this.useUuid ? item.group.uuid : item.group.id,
          };
        case "externalEmail":
          return { type: "externalEmail" as const, email: item.email };
      }
    });
    this.onChange(formValue);
    this.onTouched();

    window.setTimeout(() => {
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    }, 50);
  }

  private mapEmailToSelectionItem(email: string): SelectionItem {
    return {
      type: "externalEmail",
      key: `externalEmail-${email}`,
      email: email.toLowerCase(),
    };
  }

  private getSelectedEmails(): string[] {
    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "externalEmail" }> =>
          item.type === "externalEmail",
      )
      .map((item) => item.email.toLowerCase());
  }

  onGroupTooltipHover(group: IUserGroupSearchResult) {
    if (group.id in this.groupTooltipContent) {
      return;
    }

    this.groupTooltipContent[group.id] = "";

    if (!group.userCount) {
      return;
    }

    this.loadUsersByGroupId(
      group.id,
      this.groupTooltipDisplayUserLimit,
    ).subscribe((users) => {
      if (!users.total) {
        return;
      }

      let tooltipContent: string;
      const userCount = group.userCount!;
      if (userCount > this.groupTooltipDisplayUserLimit) {
        tooltipContent =
          $localize`:@@common|users:Users` +
          "\n" +
          users.data.map((user) => this.generateUserTooltip(user)).join("\n");

        const remainingCount = userCount - this.groupTooltipDisplayUserLimit;
        tooltipContent +=
          "\n" +
          $localize`:@@db-ui|autocomplete-users|tooltip-and-more:(And ${remainingCount}:count: more)`;
      } else {
        tooltipContent =
          $localize`:@@db-ui|autocomplete-users|tooltip-click-to-expand:Click to expand into users` +
          "\n" +
          users.data.map((user) => this.generateUserTooltip(user)).join("\n");
      }

      this.groupTooltipContent[group.id] = tooltipContent;
      this.cdRef.detectChanges();
    });
  }

  private generateUserTooltip(user: IUserInfo): string {
    return `• ${user.firstName} ${user.lastName} ${user.status === UserStatus.Inactive ? $localize`:@@common|inactive-with-brackets:(inactive)` : ""}`;
  }

  onGroupTooltipClick(group: IUserGroupSearchResult) {
    this.currentSelection = this.currentSelection.filter(
      (item) => item.type !== "group" || item.group.id !== group.id,
    );
    this.emitCurrentSelection();

    this.loadUsersByGroupId(
      group.id,
      this.groupTooltipExpandUserLimit,
    ).subscribe((users) => {
      this.currentSelection = [
        ...this.currentSelection,
        ...users.data
          .filter(
            (user) =>
              user.id &&
              !this.currentSelection.some(
                (item) => item.type === "user" && item.user.id === user.id,
              ),
          )
          .map(this.mapUserToSelectionItem.bind(this)),
      ];
      this.emitCurrentSelection();
    });
  }

  writeValue(value: FormSelectionItem[]): void {
    this.currentSelection = [];

    if (!value) {
      this.selectionChange.emit(this.currentSelection);
      this.cdRef.markForCheck();
      return;
    }

    const userIds = value
      .filter(
        (item): item is Extract<FormSelectionItem, { type: "user" }> =>
          item.type === "user",
      )
      .map((item) => item.id);

    const groupIds = value
      .filter(
        (item): item is Extract<FormSelectionItem, { type: "group" }> =>
          item.type === "group",
      )
      .map((item) => item.id);

    const externalEmails = value
      .filter(
        (item): item is Extract<FormSelectionItem, { type: "externalEmail" }> =>
          item.type === "externalEmail",
      )
      .map((item) => item.email);

    combineLatest([this.loadUsersById(userIds), this.loadGroupsById(groupIds)])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([users, groups]) => {
        this.currentSelection = [
          ...users.map(this.mapUserToSelectionItem.bind(this)),
          ...groups.map(this.mapGroupToSelectionItem.bind(this)),
          ...externalEmails.map(this.mapEmailToSelectionItem.bind(this)),
        ];
        this.selectionChange.emit(this.currentSelection);
        this.cdRef.markForCheck();
      });
  }

  registerOnChange(fn: (value: FormSelectionItem[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private onChange: (value: FormSelectionItem[]) => void = () => {};
  private onTouched: () => void = () => {};

  private loadUsersByGroupId(
    groupId: string,
    limit: number,
  ): Observable<{ data: IUserInfo[]; total: number }> {
    if (!groupId || !this.companyId) {
      return of({ data: [], total: 0 });
    }

    return this.userService
      .loadUsersForCompanyFiltered({
        companyId: this.companyId,
        userGroups: [groupId],
        offset: 0,
        limit,
        include: ["status"],
        status: this.usersStatusFilter,
      })
      .pipe(takeUntil(this.destroy$));
  }
}
