import {
  HybridWorkPolicyRuleRestrictionType,
  HybridWorkPolicyRuleLimitType,
  HybridWorkPolicyRuleCounterType,
  HybridWorkPolicyRulePeriodType,
  HybridWorkPolicyActivationDateType,
  HybridWorkPolicyFixedOfficeDayComplianceType,
  HybridWorkPolicyValidationLevel,
} from "../enums";

export interface IHybridWorkPolicyRule {
  id?: number;
  restrictionType: HybridWorkPolicyRuleRestrictionType;
  limitType: HybridWorkPolicyRuleLimitType;
  counter: number;
  counterType: HybridWorkPolicyRuleCounterType;
  period: HybridWorkPolicyRulePeriodType;
  deleteRule?: boolean;
}

export interface IHybridWorkPolicyActivationDate {
  type: HybridWorkPolicyActivationDateType;
  customDate: Date | null;
}

export interface IHybridWorkPolicy {
  name: string;
  rules: IHybridWorkPolicyRule[];
  fixedOfficeDays: number[];
  companyWidePolicy: boolean;
  userIds: string[];
  userGroupIds: string[];
  excludedUserIds: string[];
  activationDate: IHybridWorkPolicyActivationDate;
  validationLevel: HybridWorkPolicyValidationLevel;
}

export type IHybridWorkPolicyPersisted = Omit<
  IHybridWorkPolicy,
  "rules" | "activationDate"
> & {
  id: number;
  activationDate: string;
  rules: Array<
    IHybridWorkPolicyRule & {
      id: string;
      companyId: string;
      policyId: string;
    }
  >;
  createdBy: string;
  createdByUser: {
    firstName: string;
    lastName: string;
  };
  isActive: boolean;
  userCount: string;
};

export type IHybridWorkPolicySingleton = IHybridWorkPolicyPersisted & {
  users: Array<{
    id: number;
    firstname: string;
    lastname: string;
    email: string;
  }>;
};

export type IHybridWorkPolicyProgressRule = {
  periodStartDate: string;
  periodEndDate: string;
  rule: IHybridWorkPolicyRule;
  officeDaysStatusesCount: number;
  remoteDaysStatusesCount: number;
};

export type IHybridWorkPolicyFixedOfficeDayCompliance = {
  day: string;
  compliance: HybridWorkPolicyFixedOfficeDayComplianceType;
};

export type IHybridWorkPolicyProgress = {
  policy: {
    id: string;
    name: string;
    companyId: string;
    companyWidePolicy: boolean;
    isActive: boolean;
    createdBy: string;
    fixedOfficeDays: number[];
    activationDate: string;
    validationLevel: HybridWorkPolicyValidationLevel;
  } | null;
  fixedOfficeDaysCompliance: IHybridWorkPolicyFixedOfficeDayCompliance[];
  rulesProgress: IHybridWorkPolicyProgressRule[];
};
